import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async getSignedUrl({ filename, filetype }) {
    return axios
      .get(`${configService.get('apiUrl')}/document-share/get-signed-url?filename=${filename}&filetype=${filetype}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async uploadDocument({ url, file }) {
    const instance = axios.create();
    delete instance.defaults.headers.common.Authorization;
    const filetype = file.type;
    const config = {
      method: 'put',
      url,
      headers: {
        'Content-Type': filetype
      },
      data: file
    };
    return instance(config)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listDocuments({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/document-share`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async getOne({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/document-share/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async deleteOne({ id }) {
    return axios
      .delete(`${configService.get('apiUrl')}/document-share/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async postOne({ document } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/document-share/`, document)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async patchOne({ id, newDocument }) {
    return axios
      .patch(`${configService.get('apiUrl')}/document-share/${id}`, newDocument)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async sendNotifications({ params } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/send-notification-document-share`, params)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
