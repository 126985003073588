import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async getSignedUrl({ filename, filetype }) {
    return axios
      .get(`${configService.get('apiUrl')}/complain/get-signed-url?filename=${filename}&filetype=${filetype}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async uploadImage({ url, file }) {
    const instance = axios.create();
    delete instance.defaults.headers.common.Authorization;
    const fileType = file.type;
    const config = {
      method: 'put',
      url,
      headers: {
        'Content-Type': fileType
      },
      data: file
    };
    return instance(config)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listTickets({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/complain`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async getOne({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/complain/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async deleteOne({ id }) {
    return axios
      .delete(`${configService.get('apiUrl')}/complain/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async postOne({ ticket } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/complain/`, ticket)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async patchOne({ id, newTicket }) {
    return axios
      .patch(`${configService.get('apiUrl')}/complain/${id}`, newTicket)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
