import moment from 'moment';

export default class PhotoSharing {

  constructor({
    id,
    index,
    eventName,
    storeid,
    storeName,
    submittedBy,
    submittedByName,
    createdAt,
    updatedAt,
    image }) {
    this.id = id || '';
    this.index = index + 1;
    this.eventName = eventName || '';
    this.storeid = storeid || '';
    this.storeName = storeName || '';
    this.submittedByName = submittedByName || '';
    this.createdAt = createdAt ? moment(createdAt).format('LLL') : null;
    this.updatedAt = updatedAt ? moment(updatedAt).format('LLL') : null;
    this.submittedBy = submittedBy || '';
    this.image = image || '';
  }
}
