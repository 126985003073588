export default class Role {

  constructor({
    id,
    name,
    description,
    permissions,
    index = 0
  }) {
    this.id = id || '';
    this.name = name || '';
    this.description = description || '';
    this.permissions = permissions || '';
    this.index = index + 1;
  }

}
