import _ from 'lodash';
import cstSubmissionService from '../../services/cstSubmissionService';
import CSTSubmission from '../../model/cstSubmission';

const state = {
  baseUrl: '/cstTask',
  pagination: {},
  submissions: [],
  store: [],
  loading: false,
  submission: {},
  submissionUrl: null
};

const actions = {
  listSubmissions({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setSubmissions', { submissions: [], pagination: {} });

    commit('startRequest');

    cstSubmissionService
      .listSubmissions({ query })
      .then(response => {
        commit('setSubmissions', { submissions: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  uploadTask({ dispatch, commit }, query = {}, router) {
    dispatch('alert/clear', {}, { root: true });
    const { file } = query;
    cstSubmissionService
      .getSignedUrl({ filename: file.name, filetype: file.type, file })
      .then(response => {
        cstSubmissionService
          .uploadTask({ url: response.data, file })
          .then(res => {
            dispatch(
              'alert/success',
              {
                showType: 'toast',
                position: 'center',
                title: '',
                text: 'File uploaded successfully'
              }
            )
            commit('Nothing', res);
          })
          .catch(e => {
            commit('requestFailed');
            dispatch('common/handleServiceException', { e, router }, { root: true });
          });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { taskSubmission, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    cstSubmissionService
      .postOne({ taskSubmission })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Task submitted successfully.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setSubmission', { submission: {} });

    cstSubmissionService
      .getOne({ id })
      .then(response => {
        commit('setSubmission', { submission: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getStoreByUser({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setStore', { store: [] });
    commit('startRequest');

    cstSubmissionService
      .getStoreByUser()
      .then(response => {
        commit('setStore', { store: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setSubmissions(state, { submissions, pagination }) {
    let row = 1;
    state.submissions = _.map(submissions, submission => {
      const newSubmissions = new CSTSubmission({
        id: submission._id, // eslint-disable-line
        rowNum: row,
        optionSelected: submission.optionSelected,
        submittedByName: submission.submittedByName,
        cstTask: submission.cstTask.taskName ? submission.cstTask.taskName : null,
        createdAt: submission.createdAt,
        attachments: submission.attachments
      });
      row += 1;
      return newSubmissions;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setSubmission(state, { submission }) {
    state.submission = new CSTSubmission({
      id: submission._id, // eslint-disable-line
      cstTaskId: submission.cstTaskId,
      optionSelected: submission.optionSelected,
      attachments: submission.attachments ? submission.attachments : []
    });
    state.loading = false;
  },
  setStore(state, { store }) {
    state.store = store;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
