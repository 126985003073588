import moment from 'moment';

export default class Tags {
  constructor({ id, rowNum, tags, tagCategory, createdAt }) {
    this.id = id;
    this.rowNum = rowNum || '';
    this.tags = tags || '';
    this.tagCategory = tagCategory || '';
    this.createdAt = createdAt ? moment(createdAt).format('LLL') : null;
  }
}
