<template>
  <div class="app-layout app-layout-simple min-height-637">
    <b-container fluid>
      <slot />
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'SimpleLayout'
};
</script>
<style>
.min-height-637 {
  min-height: 637px;
}
</style>
