import moment from 'moment';

export default class Documents {
  constructor({
    id,
    rowNum,
    documentUrl,
    documentType,
    uploadedByName,
    createdAt,
    regions,
    clusters,
    storeids,
    formats,
    startDate,
    endDate,
    active,
    title,
    description,
    remarks,
    completeRemarks
  }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.documentUrl = documentUrl || '';
    this.documentType = documentType || '';
    this.uploadedByName = uploadedByName || '';
    this.createdAt = createdAt ? moment(createdAt).format('LL') : null;
    this.regions = regions || '';
    this.clusters = clusters || '';
    this.storeids = storeids || '';
    this.formats = formats || '';
    this.startDate = startDate ? moment(startDate).format('LL') : null;
    this.endDate = endDate ? moment(endDate).format('LL') : null;
    this.active = active ? 'True' : 'False';
    this.title = title || '';
    this.description = description || '';
    this.remarks = remarks || '';
    this.completeRemarks = completeRemarks || '';
  }
}
