import axios from 'axios';
import configService from '@/services/configService';

export default {
  async listPermission() {
    const url = `${configService.get('apiUrl')}/permissions`;

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postOne({ permission } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/permissions/`, permission)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getOne({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/permissions/${id}/`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async patchOne({ id, permission }) {
    return axios
      .patch(`${configService.get('apiUrl')}/permissions/${id}`, permission)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
