import _ from 'lodash';
import licenseService from '../../services/licenseService';
import License from '../../model/license';

const state = {
  baseUrl: '/license-upload',
  licenseList: [],
  downloadLicenseList: [],
  pagination: {},
  loading: false,
  document: null
};

const actions = {
  listLicense({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setLicenseList', { licenseList: [], pagination: {} });

    commit('startRequest');

    licenseService
      .listLicense({ query })
      .then(response => {
        commit('setLicenseList', { licenseList: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { license, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    licenseService
      .postOne({ license })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New License has been uploaded.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  download({ dispatch, commit, router }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');


    licenseService
      .listLicense({ query })
      .then(response => {
        commit('setDownloadLicenseList', {
          downloadLicenseList: response.data.rows
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      })
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadLicenseList', { downloadLicenseList: [] });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setNote(state, { note }) {
    state.note = note;
    state.loading = false;
  },
  setLicenseList(state, { licenseList, pagination }) {
    let row = 1;
    state.licenseList = licenseList.map(i => {
      const { _id } = i;
      const newLicense = new License({
        rowNum: row,
        id: _id,
        fileName: i.fileName,
        fileUrl: i.fileUrl,
        storeid: i.storeid,
        submittedByName: i.submittedByName,
        createdAt: i.createdAt,
        startDate: i.startDate,
        endDate: i.endDate
      });
      row += 1;
      return newLicense;
    });
    state.pagination = pagination;
    state.loading = false;
  },

  setDownloadLicenseList(state, { downloadLicenseList }) {
    let row = 1;
    state.downloadLicenseList = _.map(downloadLicenseList, i => {
      const { _id } = i;
      const newLicense = new License({
        rowNum: row,
        id: _id,
        fileName: i.fileName,
        fileUrl: i.fileUrl,
        storeid: i.storeid,
        submittedByName: i.submittedByName,
        createdAt: i.createdAt,
        startDate: i.startDate,
        endDate: i.endDate
      });
      row += 1;
      return newLicense;
    });
    state.loading = false;

  }

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
