import _ from 'lodash';
import settingService from '@/services/settingService';
import Setting from '../../model/setting';

const state = {
  baseUrl: '/setting',
  settings: [],
  pagination: {},
  storeList: [],
  storeData: null,
  loading: false
};

const actions = {
  listStore({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    settingService
      .listStore({ query })
      .then(response => {
        commit('setStores', { stores: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getStoreData({ dispatch, commit }, { storeId, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setStoreData', { storeData: null });

    settingService
      .getStoreData({ storeId })
      .then(response => {
        commit('setStoreData', { storeData: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchStore({ dispatch, commit }, { storeId, pincodes, managerNumbers, smsNumber, updateManagerNumber, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    settingService
      .patchStoreData({
        storeId,
        pincodes,
        managerNumbers,
        smsNumber,
        updateManagerNumber
      })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'bottom-end',
            title: '',
            text: 'Settings has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setStores(state, { stores }) {
    let rowNum = 0;
    state.storeList = _.map(stores, store => {
      rowNum += 1;
      return {
        storeId: store,
        rowNum
      }
    })
    state.loading = false;
  },
  setStoreData(state, { storeData }) {
    if (storeData) {
      const managerNumbers = storeData.store_manager_number.split(',');
      const managerInfo = managerNumbers.map((manager) => {
        const meta = storeData.managerInfo.find((m) => m.managerNumber === manager);
        if (meta) {
          return ({
            number: manager,
            name: meta.name,
            empid: meta.empid
          })
        }
        return ({
          name: null,
          empid: null,
          number: manager
        })
      })
      state.storeData = new Setting({
        pincodes: _.uniq(`${storeData.pincode},${storeData.nearby_pincode}`.split(',')),
        managerNumbers: managerInfo,
        smsNumber: storeData.sms_number
      });
    } else {
      state.storeData = null;
    }
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
