import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listNotes({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'page', 'page_size', 'filter', 'search', 'searchkey', 'startdate', 'enddate']);
    let url = `${configService.get('apiUrl')}/notes`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteOne({ id }) {
    return axios
      .delete(`${configService.get('apiUrl')}/notes/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getOne({ query = {} } = {}) {
    const { id } = query;
    const url = `${configService.get('apiUrl')}/notes/${id}`;

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async downloadNotesReport({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'startdate', 'enddate']);
    let url = `${configService.get('apiUrl')}/visit-notes-report`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      }
      ).catch(e => {
        throw e;
      }
      );
  },
  async taggingReport({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'startdate', 'enddate']);
    let url = `${configService.get('apiUrl')}/notes-tagging-report`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      }
      ).catch(e => {
        throw e;
      }
      );
  }
};
