import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listForms({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'page', 'page_size', 'filter']);
    let url = `${configService.get('apiUrl')}/form`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getInputFields({ type = 'form', id }) {
    return axios
      .get(`${configService.get('apiUrl')}/${type}/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listFormData({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'formTarget',
      'searchkey',
      'searchvalue',
      'page',
      'page_size',
      'filter',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/form/operations`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteOne({ id }) {
    return axios
      .delete(`${configService.get('apiUrl')}/form/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteFullForm({ id }) {
    return axios
      .delete(`${configService.get('apiUrl')}/formDelete/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postOne({ type = 'form', form } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/${type}`, form)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async saveFormData({ type = 'form', formData } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/${type}/operations`, formData)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async upload(data) {
    return axios
      .post(`${configService.get('apiUrl')}/form/upload`, data)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
