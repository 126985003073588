<template>
  <div class="app-layout app-layout-backend">
    <nav-bar @handleDark="toggleDarkMode" />
    <b-container :class="{ 'text-dark bg-light ': !isDarkMode, 'text-light bg-dark ': isDarkMode }" fluid>
      <slot />
      <footer-bar />
    </b-container>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import FooterBar from '@/components/FooterBar.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'BackendLayout',
  computed: {
    ...mapState('darkMode', ['isDarkMode'])
  },
  /* work in only microsoft edge */
  // mounted() {
  //   const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
  //   const isDarkMode = darkThemeMq.matches;

  //   this.changeDarkMode({ isDarkMode });
  //   localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
  // },
  methods: {
    ...mapActions('darkMode', ['changeDarkMode']),
    toggleDarkMode() {
      this.changeDarkMode({ isDarkMode: !this.isDarkMode });
    }
  },
  components: {
    NavBar,
    FooterBar
  }
};
</script>
