import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listFeedback({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'page', 'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'



    ]);
    let url = `${configService.get('apiUrl')}/feedback`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getOne({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/feedback/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postOne({ feedback } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/feedback/`, feedback)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteOne({ id }) {
    return axios
      .delete(`${configService.get('apiUrl')}/feedback/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async upload({ data } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/feedback/upload`, { feedbacks: data })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
