import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listPromo({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'page', 'page_size', 'search', 'searchkey', 'startdate', 'enddate']);
    let url = `${configService.get('apiUrl')}/promo-code`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getOne({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/promo-code/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postOne({ promoCode } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/promo-code/`, promoCode)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async patchOne({ id, promoCode }) {
    return axios
      .patch(`${configService.get('apiUrl')}/promo-code/${id}`, promoCode)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteOne({ id }) {
    return axios
      .delete(`${configService.get('apiUrl')}/promo-code/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
