import moment from 'moment';

export default class Bulk {
  constructor({
    id,
    rowNum,
    dateOfEnquiry,
    mobile,
    alternativeMobile,
    storeName,
    remarks,
    storeid,
    customerName,
    submittedByName,
    email,
    approxQuantity,
    typeOfGift,
    organizationName,
    giftPriceRange
  }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.dateOfEnquiry = dateOfEnquiry ? moment(dateOfEnquiry).format('LLL') : null;
    this.storeid = storeid || '';
    this.storeName = storeName || '';
    this.customerName = customerName || '';
    this.mobile = mobile || '';
    this.alternativeMobile = alternativeMobile || '';
    this.email = email || '';
    this.organizationName = organizationName || '';
    this.giftPriceRange = giftPriceRange || '';
    this.approxQuantity = approxQuantity || '';
    this.typeOfGift = typeOfGift || '';
    this.remarks = remarks || '';
    this.submittedByName = submittedByName || '';
  }
}
