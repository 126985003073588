import _ from 'lodash';
import userService from '@/services/userService';
import User from '../../model/user';

const state = {
  baseUrl: '/user',
  users: [],
  storeList: [],
  activeReport: [],
  rolesReport: [],
  regionReport: [],
  pieChart: [],
  activeInactiveReport: [],
  pagination: {},
  user: null,
  loading: false,
  availableRoles: []
};

const actions = {
  list({ dispatch, commit }, { type = 'user', query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setUsers', { users: [] });
    commit('startRequest');

    userService
      .list({ type, query })
      .then(response => {
        commit('setUsers', { users: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listStore({ dispatch, commit }, { router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setStores', { storeList: [] });

    userService
      .listStoreByUser()
      .then(response => {
        commit('setStores', { storeList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { type = 'user', mobile, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setUser', { user: {} });

    userService
      .getOne({ type, mobile })
      .then(response => {
        commit('setUser', { user: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { type = 'user', user, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    userService
      .postOne({ type, user })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: type === 'user' ? 'New user has been added.' : 'New staff has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { type = 'user', mobile, user, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .patchOne({ type, mobile, newUser: user })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: type === 'user' ? 'User has been updated.' : 'Staff has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  passwordChange({ dispatch, commit }, { type = 'user', mobile, passwordOld, passwordNew, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .passwordChange({ type, mobile, passwordOld, passwordNew })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Your password has been changed.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  passwordReset({ dispatch, commit }, { query, router, password, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .passwordReset({ query, passwordNew: password })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Your password has been changed.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOtp({ dispatch, commit }, { type = 'user', email, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .getOtp({ type, email })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'OTP has been sent to your email.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { type = 'user', mobile, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .deleteOne({ type, mobile })
      .then(_response => {
        dispatch('list', { type });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: type === 'user' ? 'User has been deleted.' : 'Staff has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getRoles({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .getRoles()
      /* eslint no-underscore-dangle: 0 */
      .then(response =>
        commit(
          'setRoles',
          response.data.map(item => ({ name: item.name, id: item._id }))
        )
      )
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  upload({ dispatch, commit }, { data, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    userService
      .upload({ data })
      .then(_response => {
        commit('finishRequest');
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            type: 'success',
            text: 'User(s) uploaded successfully !!'
          },
          { root: true }
        );
        router.go();
      })
      .catch(e => {
        let errorMessage = '';
        if (e.response.data.data[0]) {
          errorMessage = e.response.data.data[0].msg;
        }
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: errorMessage,
            type: 'error',
            showType: 'dialog',
            text: 'Upload failed, remember to use only sample file provided and check the line number !!'
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e }, { root: true });
      });
    commit('finishRequest');
  },
  downloadReport({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .downloadReport()
      .then(_response => {
        commit('setActiveReport', { activeReport: _response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  reportByDesignation({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .reportByDesignation()
      .then(_response => {
        commit('setPieChart', { pieChart: _response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  reportByRoles({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .reportByRoles()
      .then(_response => {
        commit('setRolesReport', { rolesReport: _response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  reportByRegion({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .reportByRegion()
      .then(_response => {
        commit('setRegionReport', { regionReport: _response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  activeInactive({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    userService
      .activeInactive()
      .then(_response => {
        commit('setActiveInactiveReport', { activeInactiveReport: _response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setStores(state, { storeList }) {
    if (storeList.length) {
      // const storeOptions = storeList.map(store => ({ text: store, value: store }))
      state.storeList = storeList;
    }
    state.loading = false;
  },
  setUsers(state, { users }) {
    let row = 1;
    state.users = _.map(users, user => {
      const newUser = new User({
        id: row,
        mobile: user.mobile,
        name: user.name,
        roles: user.roles.length ? user.roles.map(role => role.name).join(', ') : '',
        status: user.status,
        storeids: user.storeids,
        lastLogin: user.lastLogin,
        company: user.company,
        empid: user.empid,
        email: user.email,
        designation: user.designation,
        clusters: user.clusters,
        regions: user.regions
      });
      row += 1;
      return newUser;
    });
    state.loading = false;
  },
  setUser(state, { user }) {
    const { _id } = user;
    state.user = new User({
      id: _id,
      mobile: user.mobile,
      name: user.name,
      roles: user.roles ? user.roles : [],
      status: user.status,
      email: user.email,
      company: user.company,
      clusters: user.clusters ? user.clusters : [],
      regions: user.regions ? user.regions : [],
      storeids: user.storeids ? user.storeids : [],
      empid: user.empid,
      designation: user.designation,
      shouldBeNotified: user.shouldBeNotified
    });
    state.loading = false;
  },
  setRoles(state, availableRoles) {
    state.availableRoles = availableRoles;
    state.loading = false;
  },
  setActiveReport(state, { activeReport }) {
    state.activeReport = activeReport;
    state.loading = false;
  },
  setPieChart(state, { pieChart }) {
    const arr1 = pieChart.lastThreeDay;
    const arr2 = pieChart.lastSevenDay;
    const mergedArray = arr1.concat(arr2);
    state.pieChart = mergedArray;
    state.loading = false;
  },
  setRolesReport(state, { rolesReport }) {
    state.rolesReport = rolesReport;
    state.loading = false;
  },
  setRegionReport(state, { regionReport }) {
    state.regionReport = regionReport;
    // state.loading = false;
  },
  setActiveInactiveReport(state, { activeInactiveReport }) {
    state.activeInactiveReport = activeInactiveReport;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
