import moment from 'moment';

export default class Rating {
  constructor({
    id,
    index,
    vendorCode,
    vendorName,
    easeOfGettingOrder,
    easeOfSupply,
    easeOfDocumentationWhileDelivery,
    easeOfReceivingPayments,
    accessibilityForSRLaroundCommunication,
    easeOfAccountReconciliation,
    createdAt,
    updatedAt
  }) {
    this.id = id;
    this.index = index + 1;
    this.vendorCode = vendorCode || 'NA';
    this.vendorName = vendorName || 'NA';
    this.easeOfGettingOrder = easeOfGettingOrder;
    this.easeOfSupply = easeOfSupply;
    this.easeOfDocumentationWhileDelivery = easeOfDocumentationWhileDelivery;
    this.easeOfReceivingPayments = easeOfReceivingPayments;
    this.accessibilityForSRLaroundCommunication = accessibilityForSRLaroundCommunication;
    this.easeOfAccountReconciliation = easeOfAccountReconciliation;
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY hh:mm:ss') : null;
    this.updatedAt = updatedAt ? moment(updatedAt).format('DD-MM-YYYY hh:mm:ss') : null;
  }
}
