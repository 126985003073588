import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async list({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate',
      'filter2',
      'filter3',
      'filter4'
    ]);
    let url = `${configService.get('apiUrl')}/promo`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async promoList({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/promo-storeWiseReport`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async report({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate',
      'filter2'
    ]);
    let url = `${configService.get('apiUrl')}/promoReport`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async patchOne({ list }) {
    return axios
      .patch(`${configService.get('apiUrl')}/promo/update`, list)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async searchByArticle({ articleCode }) {
    const url = `${configService.get('apiUrl')}/promo/search?articleCode=${articleCode}`;

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
