import moment from "moment";

export default class Ticket {
  constructor({
    id,
    rowNum,
    name,
    mobile,
    email,
    issue,
    details,
    priority,
    storeid,
    complainNumber,
    imageUrl,
    resolvedAt,
    escalationTime,
    complainSource,
    currentStatus,
    isResolved,
    issuePriority,
    submittedByRole,
    submittedByName,
    createdAt
  }) {
    this.id = id || "";
    this.rowNum = rowNum || "";
    this.name = name || "";
    this.mobile = mobile || "";
    this.email = email || "";
    this.issue = issue || "";
    this.details = details || "";
    this.priority = priority || "";
    this.storeid = storeid || "";
    this.complainNumber = complainNumber || "";
    this.imageUrl = imageUrl || "";
    this.resolvedAt = resolvedAt ? moment(resolvedAt).format("DD-MM-YYYY hh:mm  A") : null;
    this.escalationTime = escalationTime || "";
    this.complainSource = complainSource || "";
    this.currentStatus = currentStatus || "";
    this.isResolved = isResolved || "";
    this.issuePriority = issuePriority || "";
    this.submittedByRole = submittedByRole || "";
    this.submittedByName = submittedByName || "";
    this.createdAt = createdAt ? moment(createdAt).format("DD-MM-YYYY  hh:mm A") : null;
  }
}
