import _ from 'lodash';
import misService from '@/services/misService';
import MIS from '../../model/mis';
import MisChecklist from '../../model/misChecklist';

const state = {
  baseUrl: '/dashboard/downloadmis',
  misList: [],
  misChecklist: [],
  misCountList: [],
  setDownloadMisCountList: [],
  outboundFormData: [],
  fnvFormData: [],
  misByQuestion: [],
  auditReport: [],
  silverList: [],
  loading: false
};

const actions = {
  download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setDownloadMisList', { misList: [] });
    misService
      .list({ query })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Preparing your download, please wait...'
          },
          { root: true }
        );
        commit('setDownloadMisList', { misList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  downloadMis({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setDownloadMisCountList', { misCountList: [] });
    misService
      .downloadMis({ query })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Preparing your download, please wait...'
          },
          { root: true }
        );
        commit('setDownloadMisCountList', { misCountList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  downloadMisChecklist({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setDownloadMisChecklist', { misChecklist: [], checkpoints: {} });
    misService
      .getChecklist({ query })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Preparing your download, please wait...'
          },
          { root: true }
        );
        commit('setDownloadMisChecklist', { misChecklist: response.data.data, checkpoints: response.data.checkpoint });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  downloadAuditReport({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    misService
      .downloadAuditReport({ query })
      .then(response => {
        commit('setDownloadAuditReport', {
          auditReport: response.data
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  downloadMisByQuestion({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    misService
      .downloadMisByQuestion({ query })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Preparing your download, please wait...'
          },
          { root: true }
        );
        commit('setDownloadMisByQuestion', { misByQuestion: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  downloadSilver({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    misService
      .reportCoin({ query })
      .then(response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Preparing your download, please wait...'
          },
          { root: true }
        );
        commit('setDownloadSilverList', { silverList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  outboundFormReport({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setOutboundFormData', { outboundFormData: [] });
    dispatch(
      'alert/success',
      {
        showType: 'toast',
        position: 'center',
        title: '',
        text: 'Preparing your download, please wait...'
      },
      { root: true }
    );
    misService
      .outboundFormReport({ query })
      .then(response => {
        commit('setOutboundFormData', { outboundFormData: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  FnvFormReport({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setFnvFormData', { fnvFormData: [] });
    dispatch(
      'alert/success',
      {
        showType: 'toast',
        position: 'center',
        title: '',
        text: 'Preparing your download, please wait...'
      },
      { root: true }
    );
    misService
      .FnvFormReport({ query })
      .then(response => {
        commit('setFnvFormData', { fnvFormData: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setDownloadMisList(state, { misList }) {
    let row = 1;
    state.misList = _.map(misList, mis => {
      const formattedList = new MIS({
        row,
        Zone: mis.Zone,
        Region: mis.Region,
        StoreId: mis.StoreId,
        StoreName: mis.StoreName,
        checklistName: mis.checklistName,
        Frequency: mis.Frequency,
        Format: mis.Format,
        AmRemark: mis.AmRemark,
        Remark: mis.Remark
      });
      row += 1;
      return formattedList;
    });
    state.loading = false;
  },
  setDownloadMisCountList(state, { misCountList }) {
    state.misCountList = misCountList;
    state.loading = false;
  },
  setDownloadMisChecklist(state, { misChecklist, checkpoints }) {
    state.misChecklist = {
      data: misChecklist.map(m => new MisChecklist({ ...m })),
      checkpoints
    };
    state.loading = false;
  },
  setDownloadMisByQuestion(state, { misByQuestion }) {
    state.misByQuestion = misByQuestion;
    state.loading = false;
  },
  setDownloadAuditReport(state, { auditReport }) {
    state.auditReport = auditReport;
    state.loading = false;
  },
  setDownloadSilverList(state, { silverList }) {
    state.silverList = silverList;
    state.loading = false;
  },
  setOutboundFormData(state, { outboundFormData }) {
    state.outboundFormData = outboundFormData;
    state.loading = false;
  },
  setFnvFormData(state, { fnvFormData }) {
    state.fnvFormData = fnvFormData;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
