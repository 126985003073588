import assettransferService from "@/services/assettransferService";
import _ from "lodash";
import AssetTransfer from "../../model/assettransfer";

const state = {
  baseUrl: "/atf",
  assetList: [],
  assetTransfer: [],
  asset: null,
  loading: false,
  documentUrl: null
};

const actions = {
  uploadDocument({ dispatch, commit }, query = {}, router) {
    dispatch("alert/clear", {}, { root: true });
    const { file, fileName } = query;
    assettransferService
      .getSignedUrl({ filename: fileName, filetype: file.type, file })
      .then((response) => {
        assettransferService
          .uploadDocument({ url: response.data, file })
          .then((res) => {
            commit("Nothing", res);
          })
          .catch((e) => {
            commit("requestFailed");
            dispatch("common/handleServiceException", { e, router }, {
              root: true
            });
          });
      })
      .catch((e) => {
        commit("requestFailed");
        dispatch("common/handleServiceException", { e, router }, {
          root: true
        });
      });
  },

  list({ dispatch, commit }, { query = {}, router }) {
    dispatch("alert/clear", {}, { root: true });
    commit("setAssets", { assetList: [] });
    commit("startRequest");
    assettransferService
      .list({ query })
      .then((response) => {
        commit("setAssets", { assetList: response.data });
      })
      .catch((e) => {
        commit("requestFailed");
        dispatch("common/handleServiceException", { e, router }, {
          root: true
        });
      });
  },

  listAsset({ dispatch, commit }, { router, query }) {
    dispatch("alert/clear", {}, { root: true });
    commit("setAssetsList", { assetList: [] });
    commit("startRequest");
    assettransferService
      .listAssetTransfer({ query })
      .then((response) => {
        commit("setAssetsList", { assetList: response.data.rows });
      })
      .catch((e) => {
        commit("requestFailed");
        dispatch("common/handleServiceException", { e, router }, {
          root: true
        });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch("alert/clear", {}, { root: true });
    commit("startRequest");

    commit("setSingleAsset", { asset: null });
    assettransferService
      .getOne({ id })
      .then((response) => {
        commit("setSingleAsset", { asset: response.data });
      })
      .catch((e) => {
        commit("requestFailed");
        dispatch("common/handleServiceException", { e, router }, {
          root: true
        });
      });
  },
  postOne({ dispatch, commit }, { assettransfer, router, redirectUrl = "" }) {
    dispatch("alert/clear", {}, { root: true });
    commit("startRequest");
    assettransferService
      .postOne({ assettransfer })
      .then((_response) => {
        dispatch(
          "alert/success",
          {
            showType: "toast",
            position: "center",
            title: "",
            text: "New Request Added has been added."
          },
          { root: true }
        );

        if (redirectUrl !== "") {
          router.push(redirectUrl);
        }
      })
      .catch((e) => {
        commit("requestFailed");
        dispatch("common/handleServiceException", { e, router }, {
          root: true
        });
      });
  },
  patchOne({ dispatch, commit }, { id, assettransfer, router, redirectUrl = "" }) {
    dispatch("alert/clear", {}, { root: true });
    commit("startRequest");
    assettransferService
      .patchOne({ id, assettransfer })
      .then((_response) => {
        dispatch(
          "alert/success",
          {
            showType: "toast",
            position: "center",
            title: "",
            text: "Ticket has been updated."
          },
          { root: true }
        );

        if (redirectUrl !== "") {
          router.push(redirectUrl);
        }
      })
      .catch((e) => {
        commit("requestFailed");
        dispatch("common/handleServiceException", { e, router }, {
          root: true
        });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setAssets(state, { assetList }) {
    state.assetList = assetList;
    state.loading = false;
  },
  setSingleAsset(state, { asset }) {
    if (asset) {
      state.asset = new AssetTransfer({
        id: asset._id,
        fromStoreId: asset.fromStoreId,
        toStoreId: asset.toStoreId,
        receiptImage: asset.receiptImage,
        markReceived: asset.markReceived,
        createdBy: asset.createdBy.name,
        atfDetails: asset.atfDetails,
        createdAt: asset.createdAt,
        updatedAt: asset.updatedAt
      });
    }
    state.loading = false;
  },
  setAssetsList(state, { assetList }) {
    state.assetList = _.map(assetList, (asset) => {
      const singleAsset = new AssetTransfer({
        id: asset._id,
        documentUrl: asset.documentUrl,
        fromStoreId: asset.fromStoreId,
        toStoreId: asset.toStoreId,
        markReceived: asset.markReceived,
        createdBy: asset.createdBy.name,
        atfDetails: asset.atfDetails.map((e) => e.assetDescription).join(", "),
        createdAt: asset.createdAt,
        updatedAt: asset.updatedAt
      });
      return singleAsset;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
