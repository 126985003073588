import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listBarcode({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'page', 'page_size', 'search', 'searchkey', 'startdate', 'enddate']);
    let url = `${configService.get('apiUrl')}/barcodeScan`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listCategoryStock({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'page', 'page_size', 'search', 'searchkey', 'startdate', 'enddate']);
    let url = `${configService.get('apiUrl')}/barcodeCategoryList`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async upload({ data } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/barcodeCategory/upload`, { categoryList: data })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async report({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'filter',
      'page',
      'page_size',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/osaReport`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listClass({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'filter',
      'page',
      'page_size',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/barcode-list-by-class`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
