import _ from 'lodash';
import barcodeService from '../../services/barcodeService';
import Barcode from '../../model/barcode';
import moment from 'moment';

const state = {
  baseUrl: '/barcodeScan',
  barcodeList: [],
  classList: [],
  downloadBarcodeList: [],
  report: [],
  categoryList: [],
  downloadCategoryList: [],
  pagination: {},
  loading: false
};

const actions = {
  listBarcode({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setBarcodes', { barcodes: [], pagination: {} });

    commit('startRequest');

    barcodeService
      .listBarcode({ query })
      .then(response => {
        commit('setBarcodes', { barcodes: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listCategory({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setCategory', { category: [], pagination: {} });

    commit('startRequest');

    barcodeService
      .listCategoryStock({ query })
      .then(response => {
        commit('setCategory', { category: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listClass({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setClass', { classList: [] });

    commit('startRequest');

    barcodeService
      .listClass({ query })
      .then(response => {
        commit('setClass', { classList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  async reportOSA({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setReport', { report: [] });

    commit('startRequest');

    barcodeService
      .report({ query })
      .then(response => {
        commit('setReport', { report: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  setReportToEmpty({ commit }) {
    commit('setReport', { report: [] });
  },
  async download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadBarcodeList', { downloadBarcodeList: [] });

    let stopDownload = false;
    const pageSize = 5000;
    const accData = [];

    for (let i = 0; !stopDownload; ) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await barcodeService.listBarcode({ query: { ...query, page_size: pageSize, page: i + 1 } });
        if (response.data.pagination.total_rows > 5000) {
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              position: 'center',
              title: 'Preparing your download, please wait...'
            },
            { root: true }
          );
        }
        if (response.data.rows.length === 0) {
          stopDownload = true;
        }
        accData.push(...response.data.rows);
        i += 1;
      } catch {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
        return;
      }
    }
    commit('setDownloadBarcodeList', { downloadBarcodeList: accData });
  },
  async downloadCategory({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadCategoryList', { downloadCategoryList: [] });

    let stopDownload = false;
    const pageSize = 5000;
    const accData = [];

    for (let i = 0; !stopDownload; ) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await barcodeService.listCategoryStock({
          query: { ...query, page_size: pageSize, page: i + 1 }
        });
        if (response.data.pagination.total_rows > 5000) {
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              position: 'center',
              title: 'Preparing your download, please wait...'
            },
            { root: true }
          );
        }
        if (response.data.rows.length === 0) {
          stopDownload = true;
        }
        accData.push(...response.data.rows);
        i += 1;
      } catch {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
        return;
      }
    }
    commit('setDownloadCategoryList', { downloadCategoryList: accData });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadBarcodeList', { downloadBarcodeList: [] });
  },
  async upload({ dispatch, commit }, { data, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    try {
      if (data.length >= 5000) {
        const chunkSize = 5000;
        const chunks = _.chunk(data, chunkSize);
        for (let i = 0; i < chunks.length; ) {
          const response = await barcodeService.upload({ data: chunks[i] }); // eslint-disable-line
          if (response.status === 200) {
            i += 1;
          } else {
            throw new Error('Invalid Response');
          }
        }
      } else {
        await barcodeService.upload({ data });
      }
      dispatch(
        'alert/success',
        {
          showType: 'toast',
          position: 'center',
          title: 'Upload Success',
          type: 'success',
          text: 'Upload Success'
        },
        { root: true }
      );
      router.go();
    } catch (e) {
      commit('requestFailed');
      dispatch('common/handleServiceException', { e }, { root: true });
    }
    commit('finishRequest');
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setClass(state, { classList }) {
    state.classList = classList;
  },
  setBarcodes(state, { barcodes, pagination }) {
    let row = 1;
    state.barcodeList = barcodes.map(barcode => {
      const { _id } = barcode;
      const newBarcode = new Barcode({
        id: _id,
        rowNum: row,
        barcode: barcode.barcode,
        storeid: barcode.storeid,
        articleCode: barcode.articleCode,
        articleName: barcode.articleName,
        systemCount: barcode.systemCount,
        physicalCount: barcode.physicalCount,
        category: barcode.category,
        group: barcode.group,
        submittedByName: barcode.submittedByName ? barcode.submittedByName : '',
        createdAt: barcode.createdAt,
        scannedByName: barcode.scannedByName ? barcode.scannedByName : '',
        scannedByDepartment: barcode.scannedByDepartment ? barcode.scannedByDepartment : '',
        scannedByDesignation: barcode.scannedByDesignation ? barcode.scannedByDesignation : ''
      });
      row += 1;
      return newBarcode;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setCategory(state, { category, pagination }) {
    let row = 1;
    state.categoryList = category.map(category => {
      const { _id } = category;
      const newCategory = {
        id: _id,
        rowNum: row,
        storeid: category.storeid ? category.storeid : '',
        articleCode: category.articleCode ? category.articleCode : '',
        articleDescription: category.articleDescription ? category.articleDescription : '',
        group: category.group ? category.group : '',
        class: category.class ? category.class : '',
        submittedByName: category.submittedByName ? category.submittedByName : '',
        createdAt: category.createdAt
          ? moment(category.createdAt)
              .utcOffset('+05:30')
              .format('DD-MM-YYYY HH:mm:ss')
          : '',
        updatedAt: category.updatedAt
          ? moment(category.updatedAt)
              .utcOffset('+05:30')
              .format('DD-MM-YYYY HH:mm:ss')
          : '',
        family: category.family ? category.family : '',
        subFamily: category.subFamily ? category.subFamily : '',
        numbering: category.numbering ? category.numbering : ''
      };
      row += 1;
      return newCategory;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setReport(state, { report }) {
    state.report = report;
  },
  setDownloadBarcodeList(state, { downloadBarcodeList }) {
    let row = 1;
    state.downloadBarcodeList = downloadBarcodeList.map(barcode => {
      const { _id } = barcode;
      const newBarcode = new Barcode({
        id: _id,
        rowNum: row,
        barcode: barcode.barcode,
        storeid: barcode.storeid,
        articleCode: barcode.articleCode,
        articleName: barcode.articleName,
        systemCount: barcode.systemCount,
        physicalCount: barcode.physicalCount,
        category: barcode.category,
        group: barcode.group,
        submittedByName: barcode.submittedByName ? barcode.submittedByName : '',
        createdAt: barcode.createdAt,
        scannedByName: barcode.scannedByName ? barcode.scannedByName : '',
        scannedByDepartment: barcode.scannedByDepartment ? barcode.scannedByDepartment : '',
        scannedByDesignation: barcode.scannedByDesignation ? barcode.scannedByDesignation : ''
      });
      row += 1;
      return newBarcode;
    });
    state.loading = false;
  },
  setDownloadCategoryList(state, { downloadCategoryList }) {
    let row = 1;
    state.downloadCategoryList = downloadCategoryList.map(category => {
      const { _id } = category;
      const newCategory = {
        id: _id,
        rowNum: row,
        storeid: category.storeid ? category.storeid : '',
        articleCode: category.articleCode ? category.articleCode : '',
        articleDescription: category.articleDescription ? category.articleDescription : '',
        group: category.group ? category.group : '',
        class: category.class ? category.class : '',
        submittedByName: category.submittedByName ? category.submittedByName : '',
        createdAt: category.createdAt
          ? moment(category.createdAt)
              .utcOffset('+05:30')
              .format('DD-MM-YYYY HH:mm:ss')
          : '',
        updatedAt: category.updatedAt
          ? moment(category.updatedAt)
              .utcOffset('+05:30')
              .format('DD-MM-YYYY HH:mm:ss')
          : ''
      };
      row += 1;
      return newCategory;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
