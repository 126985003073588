<template>
  <footer class="footer mt-4 pt-4 my-md-5 pt-md-5 border-top maindiv">
    <div class="row">
      <div v-if="contactUs()" class="col-12 col-md">
        <img class="mb-2 box-shadow2" src="../assets/images/Spencers_Logo.png" alt height="24" />
        <small class="d-block mb-3 text-muted">&copy; {{ year }}</small>
      </div>
    </div>
    <div>
      <!-- <marquee onmouseover="this.stop()" onmouseout="this.start();" behavior="alternate" direction="">
        <a target="_blank" href="https://share-eu1.hsforms.com/1oced_4UORcyQl2R6XUPAQwfdrk1">Contact Us</a>
      </marquee> -->
      <!-- <b-button
        v-if="show()"
        class="contactButton box-shadow2"
        target="_blank"
        @click="goToContactUs()"
        variant="primary"
      >
        <font-awesome-icon :icon="['fas', 'envelope']" class="mr-1" /> Contact Us
      </b-button> -->
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'FooterBar',
  computed: {
    ...mapState('auth', ['user'])
  },
  data() {
    return {
      year: ''
    };
  },
  methods: {
    show() {
      if (this.$route.path === '/contactUs') {
        return false;
      }
      if (this.user) {
        return true;
      } else {
        return false;
      }
    },
    goToContactUs() {
      window.open('/contactUs', '_blank');
    },
    contactUs() {
      if (this.$route.path === '/contactUs') {
        return false;
      } else {
        return true;
      }
    }
  },
  mounted() {
    this.year = new Date().getFullYear();
  }
};
</script>

<style scoped>
.maindiv {
  display: flex;
  justify-content: space-between;
}

.contactButton {
  margin-top: 7px;
}
.contactButton:hover {
  opacity: 0.8;
}
</style>
