import _ from 'lodash';
import checklistService from '@/services/checklistService';
import Vue from 'vue';
import Checklist from '../../model/checklist';

const state = {
  baseUrl: '/checklist',
  checklists: [],
  pagination: {},
  loading: false,
  checklist: null,
  checkpoints: []
};

const actions = {
  list({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setChecklists', { checklists: [], pagination: {} });

    commit('startRequest');
    checklistService
      .list({ query })
      .then(response => {
        commit('setChecklists', { checklists: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setChecklist', { checklist: {} });
    checklistService
      .getOne({ id })
      .then(response => {
        commit('setChecklist', { checklist: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { checklist, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    checklistService
      .postOne({ checklist })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New checklist has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, checklist, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    checklistService
      .patchOne({ id, newChecklist: checklist })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'checklist has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  downloadCheckpoints({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    checklistService
      .downloadCheckpoints({ id })
      .then(response => {
        commit('setCheckpoints', { checkpoints: response.data });
      })
      .catch(e => {
        if (e.response.status === 404) {
          Vue.swal({
            title: 'Checklist Not Active',
            text: 'We could not download the checkpoints because the checklist is not active.',
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6'
          });
        }
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setChecklists(state, { checklists, pagination }) {
    state.checklists = _.map(checklists, (checklist, index) => {
      const newChecklist = new Checklist({
        index,
        id: checklist._id, // eslint-disable-line
        name: checklist.name,
        active: checklist.active,
        startDate: checklist.startDate,
        endDate: checklist.endDate,
        storeids: checklist.storeids.join(','),
        clusters: checklist.clusters.join(','),
        regions: checklist.regions.join(','),
        formats: checklist.formats.join(','),
        shareTo: checklist.shareTo.map(shareTo => shareTo.email).join(','),
        permissions: checklist.permissions.map(permissions => permissions.name).join(','),
        roles: checklist.roles.map(roles => roles.name).join(','),
        frequency: checklist.frequency,
        activeTillTime: checklist.activeTillTime
      });
      return newChecklist;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setChecklist(state, { checklist }) {
    state.checklist = new Checklist({
      id: checklist._id, // eslint-disable-line
      name: checklist.name,
      active: checklist.active,
      startDate: checklist.startDate,
      endDate: checklist.endDate,
      storeids: checklist.storeids,
      clusters: checklist.clusters ? checklist.clusters : [],
      regions: checklist.regions ? checklist.regions : [],
      formats: checklist.formats ? checklist.formats : [],
      notifyTo: checklist.notifyTo ? checklist.notifyTo : [],
      shareTo: checklist.shareTo ? checklist.shareTo : [],
      permissions: checklist.permissions,
      roles: checklist.roles,
      frequency: checklist.frequency,
      checkpoint: checklist.checkpoint
        ? checklist.checkpoint.map(c => ({
            ...c,
            isCritical: c.isCritical ? 'True' : 'False',
            isImageMandatory: c.isImageMandatory ? 'True' : 'False'
          }))
        : [],
      checkpointType: checklist.checkpointType,
      isCritical: checklist.isCritical,
      activeTillTime: checklist.activeTillTime,
      isImageMandatory: checklist.isImageMandatory
    });

    state.loading = false;
  },
  setCheckpoints(state, { checkpoints }) {
    state.checkpoints = checkpoints;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
