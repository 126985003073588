import moment from "moment";

export default class CST {
  constructor({
    id,
    rowNum,
    taskName,
    taskDescription,
    date,
    communicationMode,
    active,
    storeids,
    regions,
    clusters,
    roles,
    createdAt,
    attachments,
    email
  }) {
    this.id = id;
    this.rowNum = rowNum || '';
    this.createdAt = createdAt ? moment(createdAt).format('LLL') : null;
    this.regions = regions || '';
    this.clusters = clusters || '';
    this.storeids = storeids || '';
    this.date = date ? moment(date).format('LL') : null;
    this.communicationMode = communicationMode || '';
    this.active = active ? 'True' : 'False';
    this.taskName = taskName || '';
    this.taskDescription = taskDescription || '';
    this.roles = roles || '';
    this.attachments = attachments || '';
    this.email = email || '';
  }
}
