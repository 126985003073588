import moment from "moment";

export default class CSTSubmission {
  constructor({
    id,
    rowNum,
    optionSelected,
    submittedByName,
    cstTask,
    createdAt,
    attachments
  }) {
    this.id = id;
    this.rowNum = rowNum || '';
    this.createdAt = createdAt ? moment(createdAt).format('LLL') : null;
    this.optionSelected = optionSelected || '';
    this.submittedByName = submittedByName || '';
    this.cstTask = cstTask || '';
    this.attachments = attachments || '';
  }
}
