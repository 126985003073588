import commentService from '@/services/commentService';
import Comment from '../../model/comment';

const state = {
  baseUrl: '/comments',
  comments: [],
  commentsList: [],
  comment: null,
  loading: true
};

const actions = {
  listComment({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setComments', { comments: [] });
    commit('startRequest');


    commentService
      .listComment({ query })
      .then(response => {
        commit('setComments', { comments: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },

  postReportComment({ dispatch, commit }, { comment, reportId, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commentService
      .postReportComment({ comment, reportId })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New comment has been added.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postNoteComment({ dispatch, commit }, { comment, noteId, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commentService
      .postNoteComment({ comment, noteId })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New comment has been added.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postEventComment({ dispatch, commit }, { comment, eventId, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commentService
      .postEventComment({ comment, eventId })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New comment has been added.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setComments(state, { comments }) {
    let row = 1;
    state.commentsList = comments.map(comment => {
      const { _id } = comment;
      const newComment = new Comment({
        id: _id,
        rowNum: row,
        body: comment.body,
        updatedAt: comment.updatedAt,
        docType: comment.docType,
        tag: comment.tag
      });
      row += 1;
      return newComment;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
