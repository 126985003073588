import moment from 'moment';

export default class Notes {
  constructor({
    id,
    rowNum,
    content,
    storeid,
    submittedByName,
    createdAt,
    submittedBy,
    subDepartment,
    tags,
    comments,
    roles,
    updatedAt
  }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.storeid = storeid || '';
    this.submittedByName = submittedByName || '';
    this.roles = roles || '';
    this.subDepartment = subDepartment || '';
    this.tags = tags || '';
    this.createdAt = createdAt ? createdAt : null;
    this.content = content || '';
    this.submittedBy = submittedBy || '';
    this.comments = comments || '';
    this.updatedAt = updatedAt ? moment(updatedAt).format('LLL') : null;
  }
}
