import moment from 'moment';

export default class MisChecklist {
  constructor({
    storeid,
    storeName,
    cluster,
    region,
    format,
    expectedCountQuantity,
    submittedCount,
    pendingCount,
    lastSubmittedDate,
    lastSubmittedBy,
    groupCheckpointSub
  }) {
    this.storeid = storeid;
    this.storeName = storeName;
    this.cluster = cluster;
    this.region = region;
    this.format = format;
    this.expectedCountQuantity = expectedCountQuantity;
    this.submittedCount = submittedCount;
    this.pendingCount = pendingCount;
    this.lastSubmittedDate =
      lastSubmittedDate === 'NA'
        ? lastSubmittedDate
        : moment(lastSubmittedDate)
            // .utcOffset('+05:30')
            .format('DD-MM-YYYY HH:mm');
    this.lastSubmittedBy = lastSubmittedBy;
    this.groupCheckpointSub = groupCheckpointSub;
  }
}
