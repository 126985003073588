import _ from 'lodash';
import CustomerNumberService from '../../services/CustomerNumberService';
import CustomerNumber from '../../model/CustomerNumber';

const state = {
  baseUrl: '/customer-number',
  customerNumberList: [],
  downloadNumberList: [],
  pagination: {},
  laoding: false
};

const actions = {
  listCustomer({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setCustomerNumbers', { customerNumbers: [], pagination: {} });

    commit('startRequest');

    CustomerNumberService.listCustomer({ query })
      .then(response => {
        commit('setCustomerNumbers', { customerNumbers: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  upload({ dispatch, commit }, { data, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    CustomerNumberService.upload({ data })
      .then(_response => {
        commit('finishRequest');
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            type: 'success',
            text: 'Customer Numbers uploaded successfully !!'
          },
          { root: true }
        );
        router.go();
      })
      .catch(e => {
        let errorMessage = '';
        if (e.response.data.data[0]) {
          errorMessage = e.response.data.data[0].msg;
        }
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: errorMessage,
            type: 'error',
            showType: 'dialog',
            text: 'Upload failed, remember to use only sample file provided and check the line number !!'
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e }, { root: true });
      });
    commit('finishRequest');
  },
  async download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadNumberList', { downloadNumberList: [] });

    let stopDownload = false;
    const pageSize = 9999;
    const accData = [];
    for (let i = 0; !stopDownload;) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const response = await CustomerNumberService.listCustomer({
          query: { ...query, page: i + 1, page_size: pageSize }
        });
        if (response.data.pagination.total_rows > 9999) {
          dispatch(
            'alert/success',
            {
              showType: 'toast',
              position: 'center',
              title: 'Preparing your download, please wait...'
            },
            { root: true }
          );
        }
        if (response.data.rows.length === 0) {
          stopDownload = true;
        }
        accData.push(...response.data.rows);
        i += 1;
      } catch (e) {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          { e },
          {
            root: true
          }
        );
        return;
      }
    }
    commit('setDownloadNumberList', {
      downloadNumberList: accData
    });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadNumberList', { downloadNumberList: [] });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    CustomerNumberService.deleteOne({ id })
      .then(_response => {
        dispatch('listCustomer', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Customer Number has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setCustomerNumbers(state, { customerNumbers, pagination }) {
    let row = 1;
    state.customerNumberList = customerNumbers.map(customerNumber => {
      const { _id } = customerNumber;
      const newCustomerNumber = new CustomerNumber({
        id: _id,
        rowNum: row,
        storeid: customerNumber.storeid,
        customerNumber: customerNumber.customerNumber,
        submittedBy: customerNumber.submittedBy,
        submittedByName: customerNumber.submittedByName,
        createdAt: customerNumber.createdAt
      });
      row += 1;
      return newCustomerNumber;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDownloadNumberList(state, { downloadNumberList }) {
    let row = 1;
    state.downloadNumberList = _.map(downloadNumberList, customerNumber => {
      const { _id } = customerNumber;
      const formattedCustomerNumber = new CustomerNumber({
        id: _id,
        rowNum: row,
        storeid: customerNumber.storeid,
        customerNumber: customerNumber.customerNumber,
        submittedBy: customerNumber.submittedBy,
        submittedByName: customerNumber.submittedByName,
        createdAt: customerNumber.createdAt
      });
      row += 1;
      return formattedCustomerNumber;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
