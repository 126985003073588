import moment from 'moment';


export default class CustomerNumber {
  constructor({
    id,
    rowNum,
    storeid,
    customerNumber,
    submittedBy,
    submittedByName,
    createdAt
  }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.storeid = storeid || '';
    this.customerNumber = customerNumber || '';
    this.createdAt = createdAt ? moment(createdAt).format('LL') : null;
    this.submittedByName = submittedByName || '';
    this.submittedBy = submittedBy || '';

  }
}
