import moment from 'moment';

export default class AssetTransfer {
  constructor({
    id,
    fromStoreId,
    toStoreId,
    markReceived,
    receiptImage,
    createdBy,
    atfDetails,
    createdAt,
    updatedAt,
    assetId,
    assetDescription,
    storeid,
    quantity,
    capturedDate
  }) {
    this.id = id || '';
    this.receiptImage = receiptImage || '';
    this.fromStoreId = fromStoreId || '';
    this.toStoreId = toStoreId || '';
    this.createdBy = createdBy || '';
    this.atfDetails = atfDetails || [];
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY') : null;
    this.updatedAt = updatedAt ? moment(updatedAt).format('DD-MM-YYYY') : null;
    this.assetId = assetId || '';
    this.assetDescription = assetDescription || '';
    this.markReceived = markReceived || false;
    this.storeid = storeid || '';
    this.quantity = quantity || '';
    this.capturedDate = capturedDate || '';
  }
}
