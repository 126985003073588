import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async list({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/checklist`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getOne({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/checklist/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postOne({ checklist } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/checklist/`, checklist)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async patchOne({ id, newChecklist }) {
    return axios
      .patch(`${configService.get('apiUrl')}/checklist/${id}`, newChecklist)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async downloadCheckpoints({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/download-checkpoints/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
