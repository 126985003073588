import _ from 'lodash';
import documentService from '../../services/documentService';
import Documents from '../../model/document';

const state = {
  baseUrl: '/document-share',
  pagination: {},
  documents: [],
  loading: false,
  document: null,
  documentUrl: null
};

const actions = {
  uploadDocument({ dispatch, commit }, query = {}, router) {
    dispatch('alert/clear', {}, { root: true });
    const { file, timeStamp } = query;

    documentService
      .getSignedUrl({ filename: `${timeStamp}-${file?.name}`, filetype: file.type, file })
      .then(response => {
        documentService
          .uploadDocument({ url: response.data, file })
          .then(res => {
            commit('Nothing', res);
          })
          .catch(e => {
            commit('requestFailed');
            dispatch('common/handleServiceException', { e, router }, { root: true });
          });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listDocuments({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setDocuments', { documents: [], pagination: {} });

    commit('startRequest');

    documentService
      .listDocuments({ query })
      .then(response => {
        commit('setDocuments', { documents: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { document, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    documentService
      .postOne({ document })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Document has been uploaded.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDocument', { document: {} });
    documentService
      .getOne({ id })
      .then(response => {
        commit('setDocument', { document: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, document, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    documentService
      .patchOne({ id, newDocument: document })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Document has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          dispatch('listDocuments', { query: {} });
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    documentService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listDocuments', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Document has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  sendNotifications({ dispatch, commit }, { params, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    documentService
      .sendNotifications({ params })
      .then(_response => {})
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setDocuments(state, { documents, pagination }) {
    let row = 1;
    state.documents = _.map(documents, document => {
      const newDocuments = new Documents({
        id: document._id, // eslint-disable-line
        rowNum: row,
        documentUrl: document.documentUrl,
        uploadedByName: document.uploadedByName,
        createdAt: document.createdAt,
        regions: document.regions ? document.regions.join(', ') : '',
        clusters: document.clusters ? document.clusters.join(', ') : '',
        storeids: document.storeids ? document.storeids.join(', ') : '',
        formats: document.formats ? document.formats.join(', ') : '',
        startDate: document.startDate,
        endDate: document.endDate,
        documentType: document.documentType ? document.documentType : '',
        active: document.active,
        title: document.title,
        description: document.description,
        remarks: document.remarks
          .map(i => (i?.text?.length > 35 ? `${i?.text.slice(0, 35)}...` : i?.text))
          .join('<br>'),
        completeRemarks: document.remarks
      });
      row += 1;
      return newDocuments;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDocument(state, { document }) {
    state.document = new Documents({
      id: document._id, // eslint-disable-line
      documentUrl: document.documentUrl,
      uploadedByName: document.uploadedByName,
      documentType: document.documentType ? document.documentType : '',
      createdAt: document.createdAt,
      regions: document.regions ? document.regions : [],
      clusters: document.clusters ? document.clusters : [],
      storeids: document.storeids ? document.storeids : [],
      formats: document.formats ? document.formats : [],
      startDate: document.startDate,
      endDate: document.endDate,
      active: document.active,
      title: document.title,
      description: document.description,
      remarks: document.remarks
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
