import _ from 'lodash';
import videoService from '../../services/videoService';
import Videos from '../../model/video';

const webUrl = 'https://spencers-visit.attabot.in/video/redirect';

const state = {
  baseUrl: '/video-upload',
  pagination: {},
  videos: [],
  loading: false,
  video: null,
  videoUrl: null
};

const actions = {
  setVideoNone({ commit }) {
    commit('setVideoNull');
  },
  uploadVideo({ dispatch, commit }, query = {}, router) {
    dispatch('alert/clear', {}, { root: true });
    const { file } = query;
    videoService
      .getSignedUrl({ filename: file.name, filetype: file.type, file })
      .then(response => {
        videoService
          .uploadVideo({ url: response.data, file })
          .then(res => {
            commit('Nothing', res);
          })
          .catch(e => {
            commit('requestFailed');
            dispatch('common/handleServiceException', { e, router }, { root: true });
          });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listVideos({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setVideos', { videos: [], pagination: {} });

    commit('startRequest');

    videoService
      .listVideos({ query })
      .then(response => {
        commit('setVideos', { videos: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { video, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    videoService
      .postOne({ video })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Video has been uploaded.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setVideoNull');

    videoService
      .getOne({ id })
      .then(response => {
        commit('setVideo', { video: response.data });
      })
      .catch(e => {
        commit('setVideo', { video: {} });
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, video, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    videoService
      .patchOne({ id, newVideo: video })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Video has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    videoService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listVideos', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Video has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setVideos(state, { videos, pagination }) {
    let row = 1;
    state.videos = _.map(videos, video => {
      const newVideos = new Videos({
        id: video._id, // eslint-disable-line
        rowNum: row,
        // eslint-disable-next-line no-underscore-dangle
        videoUrl: video.videoUrl ? `${webUrl}/${video._id}` : '',
        uploadedByName: video.uploadedByName,
        createdAt: video.createdAt,
        regions: video.regions ? video.regions.join(', ') : '',
        clusters: video.clusters ? video.clusters.join(', ') : '',
        storeids: video.storeids ? video.storeids.join(', ') : '',
        formats: video.formats ? video.formats.join(', ') : '',
        startDate: video.startDate,
        endDate: video.endDate,
        active: video.active,
        title: video.title,
        description: video.description,
        watchBy: video.watchBy.length,
        type: video.type
      });
      row += 1;
      return newVideos;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setVideo(state, { video }) {
    state.video = new Videos({
      id: video._id, // eslint-disable-line
      videoUrl: video.videoUrl,
      uploadedByName: video.uploadedByName,
      createdAt: video.createdAt,
      regions: video.regions ? video.regions : [],
      clusters: video.clusters ? video.clusters : [],
      storeids: video.storeids ? video.storeids : [],
      formats: video.formats ? video.formats : [],
      startDate: video.startDate,
      endDate: video.endDate,
      active: video.active,
      title: video.title,
      description: video.description,
      type: video.type
    });
    state.loading = false;
  },
  setVideoNull(state) {
    state.video = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
