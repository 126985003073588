import npsInternalService from '../../services/npsInternalService';
import NPS from '../../model/npsInternal';

const state = {
  baseUrl: '/nps',
  pagination: {},
  npsList: [],
  downloadNPSList: [],
  downloadNPSReport: [],
  downloadQuestionWise: [],
  nps: null,
  loading: true
};

const actions = {
  getOne({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setNPS', { nps: null });

    npsInternalService
      .getOne({ query })
      .then(response => {
        commit('setNPS', { nps: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listNPS({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setNPSList', { npsList: [], pagination: null });

    commit('startRequest');

    npsInternalService
      .listNPS({ query })
      .then(response => {
        commit('setNPSList', { npsList: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  download({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadNPSList', { downloadNPSList: [] });

    npsInternalService
      .listNPS({ query })
      .then(response => {
        commit('setDownloadNPSList', { downloadNPSList: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  downloadQuestionWiseReport({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    // commit('setDownloadQuestionWise', { downloadQuestionWise: [] });

    npsInternalService
      .listNPS({ query })
      .then(response => {
        commit('setDownloadQuestionWise', {
          downloadQuestionWise: response.data.rows,
          pagination: response.data.pagination
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadNPSList', { downloadNPSList: [] });
  },
  setDownloadReportToEmpty({ commit }) {
    commit('setDownloadQuestionWise', { downloadQuestionWise: [] });
  },
  downloadReportNPS({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    npsInternalService
      .downloadReportNPS({ query })
      .then(response => {
        commit('setDownloadNPSReport', { downloadNPSReport: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setNPS(state, { nps }) {
    state.nps = nps;
    state.loading = false;
  },
  setNPSList(state, { npsList, pagination }) {
    state.npsList = _.map(npsList, (nps, index) => {
      const newData = new NPS({
        index,
        id: nps._id, // eslint-disable-line
        rowNum: nps.rowNum,
        storeid: nps.storeid,
        billNumber: nps.billNumber,
        billDate: nps.billDate,
        billAmount: nps.billAmount,
        mobile: nps.mobile,
        billType: nps.billType,
        ratingDate: nps.ratingDate,
        questionGroup: nps.questionGroup,
        createdAt: nps.createdAt
      });
      return newData;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDownloadNPSList(state, { downloadNPSList }) {
    let row = 1;
    state.downloadNPSList = _.map(downloadNPSList, (nps, index) => {
      const newData = new NPS({
        index,
        rowNum: row,
        id: nps._id, // eslint-disable-line
        storeid: nps.storeid,
        billNumber: nps.billNumber,
        billDate: nps.billDate,
        billAmount: nps.billAmount,
        mobile: nps.mobile,
        billType: nps.billType,
        ratingDate: nps.ratingDate,
        questionGroup: nps.questionGroup,
        createdAt: nps.createdAt
      });
      row++;
      return newData;
    });
    state.loading = false;
  },
  setDownloadQuestionWise(state, { downloadQuestionWise }) {
    let row = 1;
    state.downloadQuestionWise = _.map(downloadQuestionWise, (nps, index) => {
      const newData = new NPS({
        index,
        rowNum: row,
        id: nps._id, // eslint-disable-line
        storeid: nps.storeid,
        billNumber: nps.billNumber,
        billType: nps.billType,
        billDate: nps.billDate,
        billAmount: nps.billAmount,
        mobile: nps.mobile,
        ratingDate: nps.ratingDate,
        questionGroup: nps.questionGroup,
        createdAt: nps.createdAt,
        submittedByName: nps.submittedByName,
        isSubmitted: nps.isSubmitted,
        actionStatus: nps.actionStatus,
        storeActionRemarks: nps.storeActionRemarks,
        updatedAt: nps.updatedAt
      });
      row++;
      return newData;
    });
    state.loading = false;
  },
  setDownloadNPSReport(state, { downloadNPSReport }) {
    state.downloadNPSReport = downloadNPSReport;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
