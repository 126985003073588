import moment from 'moment';
export default class Store {
  constructor({
    storeName,
    storeid,
    cluster,
    region,
    format,
    companyCode,
    storeManagerEmail,
    costCenter,
    pattyCashStoreGl,
    asmMobile,
    asmName,
    city,
    rsmMobile,
    company,
    rsmName,
    storeManagerMobile,
    storeManagerName,
    tradingArea,
    pettyCashCompanyName,
    asmEmail,
    businessManagerEmail,
    regionalAuditorEmail,
    regionalQualityManagerEmail,
    regionalLpManagerEmail
  }) {
    this.storeName = storeName || '';
    this.storeid = storeid || '';
    this.cluster = cluster || '';
    this.region = region || '';
    this.format = format || '';
    this.companyCode = companyCode || '';
    this.storeManagerEmail = storeManagerEmail || '';
    this.costCenter = costCenter || '';
    this.pattyCashStoreGl = pattyCashStoreGl || '';
    this.asmMobile = asmMobile || null;
    this.asmName = asmName || '';
    this.city = city || '';
    this.company = company || '';
    this.rsmMobile = rsmMobile || null;
    this.rsmName = rsmName || '';
    this.storeManagerMobile = storeManagerMobile || null;
    this.storeManagerName = storeManagerName || '';
    this.tradingArea = tradingArea || '';
    this.pettyCashCompanyName = pettyCashCompanyName || '';
    this.asmEmail = asmEmail || '';
    this.businessManagerEmail = businessManagerEmail || '';
    this.regionalAuditorEmail = regionalAuditorEmail || '';
    this.regionalQualityManagerEmail = regionalQualityManagerEmail || '';
    this.regionalLpManagerEmail = regionalLpManagerEmail || '';
  }
}
