<template>
  <div id="app" :class="{ '': !isDarkMode, 'bg-dark': isDarkMode }">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  metaInfo: {
    title: 'App',
    titleTemplate: '%s | Spencer',
    meta: []
  },
  computed: {
    ...mapState('darkMode', ['isDarkMode']),
    layout() {
      return this.$route.meta.layout || 'simple-layout';
    }
  }
};
</script>

<style lang="scss">
#app {
  // prettier-ignore
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding-top: 4.5rem;
  height: 100vh;
}

@import '../src/assets/css/custom.css';
</style>
