import _ from 'lodash';
import kycService from '@/services/kycService';
import KYC from '../../model/kyc';
import KYCInstitutional from '../../model/kycInstitutional';

const state = {
  baseUrl: '/kyc',
  kycList: [],
  kycInstitutionalList: [],
  downloadKycList: [],
  downloadKycInstitutionalList: [],
  pagination: {},
  loading: false
};

const actions = {
  listKYC({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setKycList', { kycList: [], pagination: {} });

    commit('startRequest');

    kycService
      .listKYC({ query })
      .then(response => {
        commit('setKycList', {
          kycList: response.data.rows.filter(i => i.submittedBy !== null),
          pagination: response.data.pagination
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  download({ dispatch, commit, router }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    kycService
      .listKYC({ query })
      .then(response => {
        commit('setDownloadKycList', {
          downloadKycList: response.data.rows.filter(i => i.submittedBy !== null)
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadKycList', { downloadKycList: [] });
  },
  listKYCInstitutional({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setKycInstitutionalList', { kycInstitutionalList: [], pagination: {} });

    commit('startRequest');

    kycService
      .listKYCInstitutional({ query })
      .then(response => {
        commit('setKycInstitutionalList', {
          kycInstitutionalList: response.data.rows,
          pagination: response.data.pagination
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  downloadInstitutional({ dispatch, commit, router }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    kycService
      .listKYCInstitutional({ query })
      .then(response => {
        commit('setDownloadKycInstitutionalList', {
          downloadKycInstitutionalList: response.data.rows
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  setDownloadInstitutionalListToEmpty({ commit }) {
    commit('setDownloadKycInstitutionalList', { downloadKycInstitutionalList: [] });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setNote(state, { note }) {
    state.note = note;
    state.loading = false;
  },
  setKycList(state, { kycList, pagination }) {
    let row = 1;
    state.kycList = kycList.map(kyc => {
      const { _id } = kyc;
      const newKYC = new KYC({
        rowNum: row,
        id: _id,
        customerName: kyc.customerName,
        mobile: kyc.mobile,
        address: kyc.address,
        pinCode: kyc.pinCode,
        favouriteStore: kyc.favouriteStore,
        gender: kyc.gender,
        age: kyc.age,
        communicationMode: kyc.communicationMode,
        submittedByName: kyc.submittedByName,
        createdAt: kyc.createdAt
      });
      row += 1;
      return newKYC;
    });
    state.pagination = pagination;
    state.loading = false;
  },

  setDownloadKycList(state, { downloadKycList }) {
    let row = 1;
    state.downloadKycList = _.map(downloadKycList, kyc => {
      const { _id } = kyc;
      const newKYC = new KYC({
        rowNum: row,
        id: _id,
        customerName: kyc.customerName,
        mobile: kyc.mobile,
        address: kyc.address,
        pinCode: kyc.pinCode,
        favouriteStore: kyc.favouriteStore,
        gender: kyc.gender,
        age: kyc.age,
        communicationMode: kyc.communicationMode,
        submittedByName: kyc.submittedByName,
        roles: kyc.submittedBy.roles.map(i => i.name).join(','),
        storeids:
          kyc.submittedBy.storeids.map(i => i).join(',') === '' ? '-' : kyc.submittedBy.storeids.map(i => i).join(','),
        createdAt: kyc.createdAt
      });
      row += 1;
      return newKYC;
    });
    state.loading = false;
  },
  setKycInstitutionalList(state, { kycInstitutionalList, pagination }) {
    let row = 1;
    state.kycInstitutionalList = kycInstitutionalList.map(kyc => {
      const { _id } = kyc;
      const newKYC = new KYCInstitutional({
        rowNum: row,
        id: _id,
        customerName: kyc.customerName,
        mobile: kyc.mobile,
        email: kyc.email,
        designation: kyc.designation,
        organisationName: kyc.organisationName,
        address: kyc.address,
        pinCode: kyc.pinCode,
        favouriteStore: kyc.favouriteStore,
        numberOfEmployees: kyc.numberOfEmployees,
        approxBudgetPerEmployee: kyc.approxBudgetPerEmployee,
        remarks: kyc.remarks,
        submittedByName: kyc.submittedByName,
        createdAt: kyc.createdAt
      });
      row += 1;
      return newKYC;
    });
    state.pagination = pagination;
    state.loading = false;
  },

  setDownloadKycInstitutionalList(state, { downloadKycInstitutionalList }) {
    let row = 1;
    state.downloadKycInstitutionalList = _.map(downloadKycInstitutionalList, kyc => {
      const { _id } = kyc;
      const newKYC = new KYCInstitutional({
        rowNum: row,
        id: _id,
        customerName: kyc.customerName,
        mobile: kyc.mobile,
        email: kyc.email,
        designation: kyc.designation,
        organisationName: kyc.organisationName,
        address: kyc.address,
        pinCode: kyc.pinCode,
        favouriteStore: kyc.favouriteStore,
        numberOfEmployees: kyc.numberOfEmployees,
        approxBudgetPerEmployee: kyc.approxBudgetPerEmployee,
        remarks: kyc.remarks,
        submittedByName: kyc.submittedByName,
        roles: kyc.submittedBy.roles.map(i => i.name).join(','),
        storeids:
          kyc.submittedBy.storeids.map(i => i).join(',') === '' ? '-' : kyc.submittedBy.storeids.map(i => i).join(','),
        createdAt: kyc.createdAt
      });
      row += 1;
      return newKYC;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
