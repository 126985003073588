import _ from 'lodash';
import promoCodeService from '@/services/promoCodeService';
import PromoCode from '../../model/promoCode';

const state = {
  baseUrl: '/promo-code',
  promoList: [],
  pagination: {},
  promo: null,
  loading: false
};

const actions = {
  listPromo({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });

    commit('startRequest');

    promoCodeService
      .listPromo({ query })
      .then(response => {
        commit('setPromoList', { promoList: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setPromo', { promo: {} });
    promoCodeService
      .getOne({ id })
      .then(response => {
        commit('setPromo', { promo: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { promoCode, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    promoCodeService
      .postOne({ promoCode })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Promo Code has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    promoCodeService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listPromo', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Promo Code has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, promoCode, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    promoCodeService
      .patchOne({ id, promoCode })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Promo Code has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setPromoList(state, { promoList, pagination }) {
    let row = 1;
    state.promoList = promoList.map(promo => {
      const { _id } = promo;
      const newPromo = new PromoCode({
        id: _id,
        rowNum: row,
        promoId: promo.promoId,
        message: promo.message,
        createdAt: promo.createdAt,
        updatedAt: promo.updatedAt
      });
      row += 1;
      return newPromo;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setPromo(state, { promo }) {
    state.promo = new PromoCode({
      id: promo._id, // eslint-disable-line
      promoId: promo.promoId,
      message: promo.message,
      createdAt: promo.createdAt
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
