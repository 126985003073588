import moment from 'moment';

export class Expense {
  constructor({
    id,
    index,
    storeId,
    billDate,
    amount,
    costCenter,
    isApprovedByHO,
    createdAt,
    isApproved,
    updatedAt,
    postingDate,
    recipient,
    referenceNumber,
    status,
    text,
    companyCode,
    companyName,
    glName,
    bulkReciptImage,
    createdBy,
    expenseGlCode,
    approvedBy,
    approvedByHO,
    pattyCashStoreGl,
    statusRemarks,
    isEdited
  }) {
    this.id = id || '';
    this.index = index + 1;
    this.storeId = storeId || '';
    this.glName = glName || '';
    this.costCenter = costCenter || '';
    this.billDate = billDate ? moment(billDate).format('DD-MM-YYYY') : null;
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY hh:mm: A') : null;
    this.amount = amount || '';
    this.text = text || '';
    this.bulkReciptImage = bulkReciptImage || '';
    this.status = status || '';
    this.recipient = recipient || '';
    this.approvedBy = approvedBy || {};
    this.approvedByHO = approvedByHO || '';
    this.companyName = companyName || '';
    this.statusRemarks = statusRemarks || '';
    this.createdBy = createdBy || '';
    this.expenseGlCode = expenseGlCode || '';
    this.companyCode = companyCode || '';
    this.isApproved = isApproved === true ? true : false;
    this.isEdited = isEdited || false;
    this.isApprovedByHO = isApprovedByHO === true ? true : false;
    this.pattyCashStoreGl = pattyCashStoreGl || '';
    this.postingDate = postingDate || '';
    this.referenceNumber = referenceNumber || '';
    this.updatedAt = updatedAt ? moment(updatedAt).format('DD-MM-YYYY hh:mm:  A') : null;
  }
}

export class Budget {
  constructor({
    id,
    index,
    storeId,
    createdBy,
    budgetAmount,
    isMakeLive,
    costCenter,
    companyName,
    createdAt,
    updatedAt,
    pattyCashStoreGl,
    storeType,
    validTo,
    validFrom,
    month
  }) {
    this.id = id || '';
    this.index = index + 1;
    this.companyName = companyName || '';
    this.storeType = storeType || '';
    this.storeId = storeId || '';
    this.pattyCashStoreGl = pattyCashStoreGl || '';
    this.costCenter = costCenter || '';
    this.budgetAmount = budgetAmount || '';
    this.month = month || '';
    this.createdBy = createdBy || '';
    this.isMakeLive = isMakeLive || false;
    this.validFrom = validFrom ? moment(validFrom).format('DD-MM-YYYY') : null;
    this.validTo = validTo ? moment(validTo).format('DD-MM-YYYY') : null;
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY hh:mm: A') : null;
    this.updatedAt = updatedAt ? moment(updatedAt).format('DD-MM-YYYY hh:mm:  A') : null;
  }
}
export class Transaction {
  constructor({
    id,
    index,
    storeId,
    createdBy,
    amount,
    balanceLeft,
    oldBalance,
    createdAt,
    status,
    updatedAt,
    budgetType
  }) {
    this.id = id || '';
    this.index = index + 1;
    this.storeId = storeId || '';
    this.budgetType = budgetType || '';
    this.amount = amount || '';
    this.balanceLeft = balanceLeft || '';
    this.oldBalance = oldBalance || 0;
    this.createdBy = createdBy || '';
    this.status = status || '';
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY hh:mm: A') : null;
    this.updatedAt = updatedAt ? moment(updatedAt).format('DD-MM-YYYY hh:mm:  A') : null;
  }
}
export class BudgetIncrease {
  constructor({
    id,
    index,
    statusRemarks,
    storeId,
    createdBy,
    amount,
    pattyCashStoreGl,
    createdAt,
    status,
    isApprovedByRSM,
    approvedByRSM,
    updatedAt,
    reasonText,
    isApproved
  }) {
    this.id = id || '';
    this.index = index + 1;
    this.storeId = storeId || '';
    this.isApproved = isApproved || false;
    this.amount = amount || '';
    this.statusRemarks = statusRemarks || '';
    this.pattyCashStoreGl = pattyCashStoreGl || '';
    this.approvedByRSM = approvedByRSM || '';
    this.createdBy = createdBy || '';
    this.status = status || '';
    this.reasonText = reasonText || '';
    this.isApprovedByRSM = isApprovedByRSM || false;
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY hh:mm: A') : null;
    this.updatedAt = updatedAt ? moment(updatedAt).format('DD-MM-YYYY hh:mm:  A') : null;
  }
}
export class CashSnapshot {
  constructor({
    id,
    index,
    createdAt,
    companyName,
    createdBy,
    currentBalance,
    monthlyOpeningBalance,
    monthlyOpeningBalanceDate,
    pattyCashStoreGl,
    storeId,
    startingBalance,
    startingBalanceDate,
    updatedAt
  }) {
    this.id = id || '';
    this.index = index + 1;
    this.companyName = companyName || '';
    this.storeId = storeId || '';
    this.pattyCashStoreGl = pattyCashStoreGl || '';
    this.monthlyOpeningBalance = monthlyOpeningBalance || 0;
    this.currentBalance = currentBalance || 0;
    this.updatedAt = updatedAt ? moment(updatedAt).format('DD-MM-YYYY hh:mm:  A') : null;
    this.startingBalanceDate = startingBalanceDate ? moment(startingBalanceDate).format('DD-MM-YYYY hh:mm:  A') : null;
    this.startingBalance = startingBalance || 0;
    this.monthlyOpeningBalanceDate = monthlyOpeningBalanceDate
      ? moment(monthlyOpeningBalanceDate).format('DD-MM-YYYY hh:mm:  A')
      : null;
    this.createdBy = createdBy || '';
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY hh:mm: A') : null;
  }
}
export class CashTransaction {
  constructor({
    id,
    index,
    amount,
    balanceLeft,
    createdBy,
    createdAt,
    storeId,
    oldBalance,
    transactionType,
    expenseGlCode,
    glName,
    pattyCashStoreGl,
    pattyCashSnapshotId,
    type,
    updatedAt
  }) {
    this.id = id || '';
    this.index = index + 1;
    this.storeId = storeId || '';
    this.transactionType = transactionType || '';
    this.pattyCashStoreGl = pattyCashStoreGl || '';
    this.oldBalance = oldBalance || 0;
    this.amount = amount || 0;
    this.balanceLeft = balanceLeft || 0;
    this.updatedAt = updatedAt ? moment(updatedAt).format('DD-MM-YYYY hh:mm:  A') : null;
    this.glName = glName || '';
    this.pattyCashSnapshotId = pattyCashSnapshotId || '';
    this.type = type || '';
    this.expenseGlCode = expenseGlCode || '';
    this.createdBy = createdBy || '';
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY hh:mm: A') : null;
  }
}

export class CashSummary {
  constructor({ totalBudget, totalBudgetBalance, totalPattyCashBalance, totalUsedBudget, totalUsedPattyCash }) {
    this.totalBudget = totalBudget || 0;
    this.totalBudgetBalance = totalBudgetBalance || 0;
    this.totalPattyCashBalance = totalPattyCashBalance || 0;
    this.totalUsedBudget = totalUsedBudget || 0;
    this.totalUsedPattyCash = totalUsedPattyCash || 0;
  }
}
// 'Serial No,Company Code ,Document Date ,Posting Date,Doc Type ,Currency ,Reference No ,Docu Head Text ,Posting Key ,Account No ,Spl Gl Indicator ,Amount ,Due Date ,Section Code ,Business Place ,Assignment Field ,Text ,Profit Center ,Cost Center ,Site
export class SAPReport {
  constructor({
    accountNumber,
    amount,
    assignmentField,
    businessPlace,
    companyCode,
    companyName,
    costCenter,
    createdAt,
    currency,
    docuHeadText,
    documentDate,
    documentType,
    dueDate,
    postingDate,
    postingKey,
    profitCenter,
    referenceNumber,
    sectionCode,
    site,
    specialGlIndicator,
    text,
    updatedAt,
    rowNum,
    documentNumber,
    revMessage,
    id
  }) {
    this.rowNum = rowNum + 1;
    this.companyCode = companyCode || '';
    this.documentDate = documentDate ? moment(documentDate).format('DD-MM-YYYY') : '';
    this.postingDate = postingDate ? moment(postingDate).format('DD-MM-YYYY') : '';
    this.documentType = documentType || '';
    this.currency = currency || '';
    this.referenceNumber = referenceNumber || '';
    this.docuHeadText = docuHeadText || '';
    this.postingKey = postingKey || '';
    this.accountNumber = accountNumber || '';
    this.specialGlIndicator = specialGlIndicator || '';
    this.amount = amount || '';
    this.dueDate = dueDate ? moment(dueDate).format('DD-MM-YYYY') : '';
    this.sectionCode = sectionCode || '';
    this.businessPlace = businessPlace || '';
    this.assignmentField = assignmentField || '';
    this.text = text || '';
    this.profitCenter = profitCenter || '';
    this.costCenter = costCenter || '';
    this.site = site || '';
    this.companyName = companyName || '';
    this.createdAt = createdAt || '';
    this.updatedAt = updatedAt || '';
    this.id = id || '';
    this.documentNumber = documentNumber || '';
    this.revMessage = revMessage || '';
  }
}
