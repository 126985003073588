import _ from 'lodash';
import bulkService from '@/services/bulkService';
import Bulk from '../../model/bulk';

const state = {
  baseUrl: '/bulk',
  bulkList: [],
  downloadBulkList: [],
  pagination: {},
  loading: false
};

const actions = {
  listBulk({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setBulks', { bulks: [], pagination: {} });

    commit('startRequest');

    bulkService
      .listBulk({ query })
      .then(response => {
        commit('setBulks', { bulks: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  upload({ dispatch, commit }, { data, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    bulkService
      .upload({ data })
      .then(_response => {
        commit('finishRequest');
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            type: 'success',
            text: 'Bulk(s) uploaded successfully !!'
          },
          { root: true }
        );
        router.go();
      })
      .catch(e => {
        let errorMessage = '';
        if (e.response.data.data[0]) {
          errorMessage = e.response.data.data[0].msg;
        }
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: errorMessage,
            type: 'error',
            showType: 'dialog',
            text: 'Upload failed, remember to use only sample file provided and check the line number !!'
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e }, { root: true });
      });
    commit('finishRequest');
  },
  download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadBulkList', { downloadBulkList: [] });

    bulkService
      .listBulk({ query })
      .then(response => {
        commit('setDownloadBulkList', {
          downloadBulkList: response.data.rows
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          { e },
          {
            root: true
          }
        );
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadBulkList', { downloadBulkList: [] });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    bulkService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listBulk', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Bulk has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setBulks(state, { bulks, pagination }) {
    let row = 1;
    state.bulkList = bulks.map(bulk => {
      const { _id } = bulk;
      const newBulk = new Bulk({
        id: _id,
        rowNum: row,
        dateOfEnquiry: bulk.dateOfEnquiry,
        storeid: bulk.storeid,
        storeName: bulk.storeName,
        customerName: bulk.customerName,
        mobile: bulk.mobile,
        alternativeMobile: bulk.alternativeMobile,
        email: bulk.email,
        organizationName: bulk.organizationName,
        giftPriceRange: bulk.giftPriceRange,
        approxQuantity: bulk.approxQuantity,
        typeOfGift: bulk.typeOfGift,
        remarks: bulk.remarks,
        submittedByName: bulk.submittedByName
      });
      row += 1;
      return newBulk;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDownloadBulkList(state, { downloadBulkList }) {
    let row = 1;
    state.downloadBulkList = _.map(downloadBulkList, bulk => {
      const { _id } = bulk;
      const formattedBulk = new Bulk({
        id: _id,
        rowNum: row,
        dateOfEnquiry: bulk.dateOfEnquiry,
        storeid: bulk.storeid,
        storeName: bulk.storeName,
        customerName: bulk.customerName,
        mobile: bulk.mobile,
        alternativeMobile: bulk.alternativeMobile,
        email: bulk.email,
        organizationName: bulk.organizationName,
        giftPriceRange: bulk.giftPriceRange,
        approxQuantity: bulk.approxQuantity,
        typeOfGift: bulk.typeOfGift,
        remarks: bulk.remarks,
        submittedByName: bulk.submittedByName
      });
      row += 1;
      return formattedBulk;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
