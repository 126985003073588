import _ from 'lodash';
import scheduleService from '@/services/scheduleService';
import Schedule from '../../model/schedule';


const state = {
  baseUrl: '/schedule',
  pagination: {},
  scheduleList: [],
  report: [],
  downloadSchedulesList: [],
  loading: true
};

const actions = {
  listSchedule({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setSchedules', { schedules: [], pagination: {} });
    commit('startRequest');


    scheduleService
      .listSchedule({ query })
      .then(response => {
        commit('setSchedules', { schedules: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadSchedulesList', { downloadSchedulesList: [] });

    scheduleService
      .listSchedule({ query })
      .then(response => {
        commit('setDownloadSchedulesList', {
          downloadSchedulesList: response.data.rows
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          { e },
          {
            root: true
          }
        );
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadSchedulesList', { downloadSchedulesList: [] });
  },
  downloadReport({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    dispatch(
      'alert/success',
      {
        showType: 'toast',
        position: 'center',
        title: '',
        text: ' Preparing your download.'
      },
      { root: true }
    );

    scheduleService
      .downloadReport({ query })
      .then(response => {
        commit('setReport', { report: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setSchedules(state, { schedules, pagination }) {
    let row = 1;
    state.scheduleList = schedules.map(schedule => {
      const { _id } = schedule;
      const newSchedule = new Schedule({
        id: _id,
        rowNum: row,
        scheduleDate: schedule.scheduleDate,
        stores: schedule.stores ? schedule.stores.map(i => i.name).join(',') : '',
        createdByName: schedule.createdByName,
        scheduleType: schedule.scheduleType,
        createdAt: schedule.createdAt

      });
      row += 1;
      return newSchedule;
    });
    state.pagination = pagination;

    state.loading = false;
  },
  setDownloadSchedulesList(state, { downloadSchedulesList }) {
    let row = 1;
    state.downloadSchedulesList = _.map(downloadSchedulesList, schedule => {
      const { _id } = schedule;
      const formattedSchedules = new Schedule({
        id: _id,
        rowNum: row,
        stores: schedule.stores ? schedule.stores.map(i => i.name).join(',') : '',
        scheduleDate: schedule.scheduleDate,
        createdByName: schedule.createdByName,
        scheduleType: schedule.scheduleType,
        createdAt: schedule.createdAt
      });
      row += 1;
      return formattedSchedules;
    });
    state.loading = false;
  },
  setReport(state, { report }) {
    state.report = report;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
