import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async list({ storeId }) {
    let url = `${configService.get('apiUrl')}/checklist/store?storeId=${storeId}`;

    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async createReport({ params } = {}) {
    let url = `${configService.get('apiUrl')}/report`;

    return axios
      .post(url, params)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async submitCheckpoint({ params } = []) {
    let url = `${configService.get('apiUrl')}/report/checkpoint`;

    return axios
      .post(url, params)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getUserSavedReport() {
    let url = `${configService.get('apiUrl')}/report/user`;

    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async checkpoints({ id }) {
    let url = `${configService.get('apiUrl')}/checkpoint?checklistId=${id}`;

    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async userFilledReports({ query = {} } = {}) {
    const { id } = query;
    const url = `${configService.get('apiUrl')}/report/${id}`;

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async patchReport({ params } = {}) {
    const { reportId } = params;
    return axios
      .patch(`${configService.get('apiUrl')}/report/${reportId}`, params)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
  // async getUserSavedCheckpoints() {
  //   let url = `${configService.get('apiUrl')}/report/user`;

  //   return axios
  //     .get(url)
  //     .then(response => {
  //       return response.data;
  //     })
  //     .catch(e => {
  //       throw e;
  //     });
  // }
};
