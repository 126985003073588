import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';


export default {
  async listSchedule({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'page', 'search', 'searchkey', 'page_size', 'filter', 'startdate', 'enddate'])
    let url = `${configService.get('apiUrl')}/schedule`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async downloadReport({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q']);
    let url = `${configService.get('apiUrl')}/visit-schedule-report`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      }
      ).catch(e => {
        throw e;
      }
      );
  }
};
