import _ from 'lodash';
import cstService from '../../services/cstService';
import CST from '../../model/cst';

const state = {
  baseUrl: '/cstTask',
  pagination: {},
  tasks: [],
  loading: false,
  task: {},
  taskUrl: null
};

const actions = {
  uploadTask({ dispatch, commit }, query = {}, router) {
    dispatch('alert/clear', {}, { root: true });
    const { file } = query;
    cstService
      .getSignedUrl({ filename: file.name, filetype: file.type, file })
      .then(response => {
        cstService
          .uploadTask({ url: response.data, file })
          .then(res => {
            dispatch(
              'alert/success',
              {
                showType: 'toast',
                position: 'center',
                title: '',
                text: 'File uploaded successfully'
              }
            )
            commit('Nothing', res);
          })
          .catch(e => {
            commit('requestFailed');
            dispatch('common/handleServiceException', { e, router }, { root: true });
          });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listTasks({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setTasks', { tasks: [], pagination: {} });

    commit('startRequest');

    cstService
      .listTasks({ query })
      .then(response => {
        commit('setTasks', { tasks: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { task, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    cstService
      .postOne({ task })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Task has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setTask', { task: {} });

    cstService
      .getOne({ id })
      .then(response => {
        commit('setTask', { task: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, task, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    cstService
      .patchOne({ id, newTask: task })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Task has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    cstService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listTasks', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Task has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setTasks(state, { tasks, pagination }) {
    let row = 1;
    state.tasks = _.map(tasks, task => {
      const newTasks = new CST({
        id: task._id, // eslint-disable-line
        rowNum: row,
        taskName: task.taskName,
        taskDescription: task.taskDescription,
        date: task.date,
        communicationMode: task.communicationMode,
        active: task.active,
        storeids: task.storeids.join(', '),
        regions: task.regions.join(', '),
        clusters: task.clusters.join(', '),
        roles: task.roles.map(role => role.name).join(', '),
        createdAt: task.createdAt,
        attachments: task.attachments
      });
      row += 1;
      return newTasks;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setTask(state, { task }) {
    state.task = new CST({
      id: task._id, // eslint-disable-line
      regions: task.regions ? task.regions : [],
      clusters: task.clusters ? task.clusters : [],
      storeids: task.storeids ? task.storeids : [],
      date: task.date,
      communicationMode: task.communicationMode,
      active: task.active,
      taskName: task.taskName,
      taskDescription: task.taskDescription,
      roles: task.roles ? task.roles : [],
      attachments: task.attachments ? task.attachments : [],
      email: task.email ? task.email : [],
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
