import moment from "moment";

export default class KYC {
  constructor({
    id,
    rowNum,
    customerName,
    mobile,
    address,
    pinCode,
    favouriteStore,
    gender,
    age,
    communicationMode,
    submittedByName,
    createdAt,
    roles,
    storeids
  }) {
    this.id = id;
    this.rowNum = rowNum || '';
    this.customerName = customerName || 'NA';
    this.mobile = mobile || 'NA';
    this.address = address || 'NA';
    this.pinCode = pinCode || 'NA';
    this.favouriteStore = favouriteStore || 'NA';
    this.gender = gender || 'NA';
    this.age = age || 'NA';
    this.communicationMode = communicationMode || 'NA';
    this.submittedByName = submittedByName || '';
    this.roles = roles || '';
    this.storeids = storeids || '';
    this.createdAt = createdAt ? moment(createdAt).format('LL') : null;
  }
}
