import axios from 'axios';
import * as vueConfig from '../../vue.config';

class ConfigService {
  constructor() {
    this.config = {};
  }

  async loadConfig() {
    let response = null;
    if (process.env.NODE_ENV === 'development') {
      response = await axios.get(`${vueConfig.publicPath}static/config-dev.json`);
    } else {
      response = await axios.get(`${vueConfig.publicPath}static/config.json`);
    }
    this.config = response.data;
  }

  set(key, value) {
    this.config[key] = value;
  }

  get(key) {
    return this.config[key];
  }
}

export default new ConfigService();
