import _ from 'lodash';
import ratingService from '../../services/ratingService';
import Rating from '../../model/rating';

const state = {
  baseUrl: '/vendorFeedback',
  ratings: [],
  downloadRatings: [],
  pagination: {},
  loading: false,
  rating: null
};

const actions = {
  list({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setRatings', { ratings: [], pagination: {} });

    commit('startRequest');

    ratingService
      .listVendor({ query })
      .then(response => {
        commit('setRatings', { ratings: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  download({ dispatch, commit, router }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    ratingService
      .listVendor({ query })
      .then(response => {
        commit('setDownloadRatings', {
          downloadRatings: response.data.rows
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadRatings', { downloadRatings: [] });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setRating', { rating: {} });

    ratingService
      .getOne({ id })
      .then(response => {
        commit('setRating', { rating: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, rating, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    ratingService
      .patchOne({ id, rating })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Feedback has been successfully Submitted.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { rating, router, redirectUrl = '', query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    ratingService
      .postOne({ rating, query })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Feedback has been Submitted Successfully.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setRatings(state, { ratings, pagination }) {
    state.ratings = _.map(ratings, (rating, index) => {
      const newRating = new Rating({
        index,
        id: rating._id, // eslint-disable-line
        vendorCode: rating.vendorCode,
        vendorName: rating.vendorName,
        POnumber: rating.POnumber,
        feedbackSubmitted: rating.feedbackSubmitted,
        link: rating.link,
        easeOfGettingOrder: rating.easeOfGettingOrder,
        easeOfSupply: rating.easeOfSupply,
        easeOfDocumentationWhileDelivery: rating.easeOfDocumentationWhileDelivery,
        easeOfReceivingPayments: rating.easeOfReceivingPayments,
        accessibilityForSRLaroundCommunication: rating.accessibilityForSRLaroundCommunication,
        easeOfAccountReconciliation: rating.easeOfAccountReconciliation,
        createdAt: rating.createdAt,
        updatedAt: rating.updatedAt
      });
      return newRating;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDownloadRatings(state, { downloadRatings }) {
    let row = 1;
    state.downloadRatings = _.map(downloadRatings, (rating, index) => {
      const newRating = new Rating({
        index,
        id: rating._id, // eslint-disable-line
        vendorCode: rating.vendorCode,
        vendorName: rating.vendorName,
        POnumber: rating.POnumber,
        feedbackSubmitted: rating.feedbackSubmitted,
        link: rating.link,
        easeOfGettingOrder: rating.easeOfGettingOrder,
        easeOfSupply: rating.easeOfSupply,
        easeOfDocumentationWhileDelivery: rating.easeOfDocumentationWhileDelivery,
        easeOfReceivingPayments: rating.easeOfReceivingPayments,
        accessibilityForSRLaroundCommunication: rating.accessibilityForSRLaroundCommunication,
        easeOfAccountReconciliation: rating.easeOfAccountReconciliation,
        createdAt: rating.createdAt,
        updatedAt: rating.updatedAt,
        row: row++
      });
      return newRating;
    });
    state.loading = false;
  },
  setRating(state, { rating }) {
    state.rating = new Rating({
      id: rating._id, // eslint-disable-line
      vendorCode: rating.vendorCode,
      vendorName: rating.vendorName,
      POnumber: rating.POnumber,
      feedbackSubmitted: rating.feedbackSubmitted,
      easeOfGettingOrder: rating.easeOfGettingOrder,
      easeOfSupply: rating.easeOfSupply,
      easeOfDocumentationWhileDelivery: rating.easeOfDocumentationWhileDelivery,
      easeOfReceivingPayments: rating.easeOfReceivingPayments,
      accessibilityForSRLaroundCommunication: rating.accessibilityForSRLaroundCommunication,
      easeOfAccountReconciliation: rating.easeOfAccountReconciliation,
      createdAt: rating.createdAt,
      updatedAt: rating.updatedAt
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
