import _ from 'lodash';
import notesService from '@/services/notesService';
import Notes from '../../model/notes';
import moment from 'moment';

const state = {
  baseUrl: '/notes',
  notesList: [],
  downloadNotesList: [],
  pagination: {},
  notesReport: [],
  taggedReport: [],
  note: null,
  comment: null,
  loading: false
};

const actions = {
  listNotes({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setNotes', { notes: [], pagination: {} });

    commit('startRequest');

    notesService
      .listNotes({ query })
      .then(response => {
        commit('setNotes', { notes: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setNote', { note: {} });

    notesService
      .getOne({ query })
      .then(response => {
        commit('setNote', { note: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadNotesList', { downloadNotesList: [] });

    notesService
      .listNotes({ query })
      .then(response => {
        commit('setDownloadNotesList', {
          downloadNotesList: response.data.rows
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          { e },
          {
            root: true
          }
        );
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadNotesList', { downloadNotesList: [] });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    notesService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listNotes', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Note has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  downloadNotesReport({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    notesService
      .downloadNotesReport({ query })
      .then(response => {
        commit('setNotesReport', { notesReport: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  taggingReport({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    dispatch(
      'alert/success',
      {
        showType: 'toast',
        position: 'center',
        title: '',
        text: ' Preparing your download.'
      },
      { root: true }
    );

    notesService
      .taggingReport({ query })
      .then(response => {
        commit('setTaggedReport', { taggedReport: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setNote(state, { note }) {
    state.note = note;
    state.loading = false;
  },
  setNotes(state, { notes, pagination }) {
    let row = 1;
    state.notesList = notes.map(notes => {
      const { _id } = notes;
      const newNotes = new Notes({
        rowNum: row,
        id: _id,
        content: notes.content.map(i => i.text).join('<br>'),
        tags: notes.tags ? notes.tags.join(', ') : '',
        storeid: notes.storeid,
        submittedByName: notes.submittedByName,
        comments: notes.comments ? notes.comments : '',
        submittedBy: notes.submittedBy.subDepartment,
        createdAt: moment(notes.createdAt).format('DD-MM-YYYY  hh:mm: A'),
        roles: notes.submittedBy.roles.map(i => i.name).join(', ')
      });
      row += 1;
      return newNotes;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setComment(state, { comment }) {
    state.comment = new Notes({
      id: comment._id, // eslint-disable-line
      comments: comment.comments
    });
  },
  setDownloadNotesList(state, { downloadNotesList }) {
    let row = 1;
    state.downloadNotesList = _.map(downloadNotesList, notes => {
      const { _id } = notes;
      const formattedNotes = new Notes({
        id: _id,
        rowNum: row,
        content: notes.content,
        storeid: notes.storeid,
        tags: notes.tags,
        submittedByName: notes.submittedByName,
        submittedBy: notes.submittedBy.subDepartment,
        subDepartment: notes.submittedBy.subDepartment,
        createdAt: moment(notes.createdAt).format('DD-MM-YYYY  hh:mm: A'),
        roles: notes.submittedBy.roles.map(i => i.name).join(', '),
        updatedAt: notes.updatedAt
      });
      row += 1;
      return formattedNotes;
    });
    state.loading = false;
  },
  setNotesReport(state, { notesReport }) {
    state.notesReport = notesReport;
    state.loading = false;
  },
  setTaggedReport(state, { taggedReport }) {
    state.taggedReport = taggedReport;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
