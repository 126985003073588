export default class Brand {
  constructor({
    id,
    rowNum,
    storeid,
    storeName,
    cityName,
    itemName,
    brandName,
    description,
    mrp,
    localSellingPrice,
    category,
    submittedByName
  }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.storeid = storeid || '';
    this.storeName = storeName || '';
    this.cityName = cityName || '';
    this.itemName = itemName || '';
    this.brandName = brandName || '';
    this.description = description || '';
    this.mrp = mrp || '';
    this.localSellingPrice = localSellingPrice || '';
    this.category = category || '';
    this.submittedByName = submittedByName || '';
  }
}
