import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listEvents({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate',
      'expired'
    ]);
    // pickedQuery.expired = false;
    let url = `${configService.get('apiUrl')}/event-plan`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postOne({ event } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/event-plan/`, event)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getOne({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/event-plan/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async patchOne({ id, newEvent }) {
    return axios
      .patch(`${configService.get('apiUrl')}/event-plan/${id}`, newEvent)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteOne({ id }) {
    return axios
      .delete(`${configService.get('apiUrl')}/event-plan/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async downloadMis({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'startdate', 'enddate']);
    let url = `${configService.get('apiUrl')}/new-event-mis`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
