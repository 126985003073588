import moment from 'moment';

export default class PromoCode {
  constructor({ id, rowNum, promoId, message, createdAt, updatedAt }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.promoId = promoId || '';
    this.message = message || '';
    this.createdAt = createdAt ? moment(createdAt).format('LLL') : null;
    this.updatedAt = updatedAt ? moment(updatedAt).format('LLL') : null;
  }
}
