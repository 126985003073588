import _ from 'lodash';
import checklistV2FillService from '../../services/checklistV2FillService';
import Vue from 'vue';
import { ReportV2, ChecklistV2, LastMonthRecord } from '../../model/checklistV2';
import moment from 'moment';

const state = {
  baseUrl: '/checklistV2',
  checklistsV2: [],
  pagination: {},
  loading: false,
  storeSelected: localStorage.getItem('storeId') || '',
  fillingCheckpoints: [],
  report: {},
  userFilledReport: [],
  userFilledCheckpoints: [],
  checkpointResponse: [],
  categoriesAndDepartments: {},
  singleReportData: {},
  reviewChecklistsV2: [],
  releasedReportsV2: [],
  downloadDashboard: [],
  downloadReportsV2: [],
  lastMonthFilledChecklist: [],
  lastMonthFilledObservations: [],
  keyPointReports: []
};

const actions = {
  list({ dispatch, commit }, { storeId, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setChecklistsV2', { checklistsV2: [], pagination: {} });

    commit('startRequest');
    checklistV2FillService
      .list({ storeId })
      .then(response => {
        commit('setChecklistsV2', { checklistsV2: response.data, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  reviewChecklists({ dispatch, commit }, { router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setReviewChecklistsV2', { reviewChecklistsV2: [], pagination: {} });

    commit('startRequest');
    checklistV2FillService
      .reviewChecklists()
      .then(response => {
        commit('setReviewChecklistsV2', {
          reviewChecklistsV2: response.data.rows,
          pagination: response.data.pagination
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listReleasedReports({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setReleasedReportsV2', { releasedReportsV2: [], pagination: {} });

    commit('startRequest');

    checklistV2FillService
      .listReleasedReports({ query })
      .then(response => {
        commit('setReleasedReportsV2', {
          releasedReportsV2: response.data.rows,
          pagination: response?.data?.pagination
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  downloadDashboardReport({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setDownloadDashboardReport', { downloadDashboard: [] });

    commit('startRequest');

    checklistV2FillService
      .listReleasedReports({ query })
      .then(response => {
        commit('setDownloadDashboardReport', {
          downloadDashboard: response.data.rows
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  downloadReleasedReports({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setDownloadReportsV2', { downloadReportsV2: [], pagination: {} });
    commit('startRequest');

    checklistV2FillService
      .listReleasedReports({ query })
      .then(response => {
        commit('setDownloadReportsV2', {
          downloadReportsV2: response.data.rows
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  downloadKeyPointReports({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setKeyPointReports', { keyPointReports: [] });

    commit('startRequest');

    checklistV2FillService
      .downloadKeyPointReports({ query })
      .then(response => {
        commit('setKeyPointReports', {
          keyPointReports: response.data
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },

  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setFillingCheckpoints', { fillingCheckpoints: [] });
    commit('startRequest');
    checklistV2FillService
      .getOne({ id })
      .then(response => {
        commit('setFillingCheckpoints', { fillingCheckpoints: response.data.checkpoints });
        commit('setCategoriesAndDepartments', { categoriesAndDepartments: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOneReportData({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setSingleReportData', { singleReportData: [] });
    commit('startRequest');
    checklistV2FillService
      .getOneReportData({ id })
      .then(response => {
        commit('setSingleReportData', { singleReportData: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  selectStore({ dispatch, commit }, { storeId }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setSelectedStore', { storeSelected: '', pagination: {} });
    commit('setSelectedStore', { storeSelected: storeId });
  },
  async generateReport({ dispatch, commit }, { reportID, params, singleCheckpoint, router, redirectUrl }) {
    let reportId;
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    if (reportID) {
      reportId = reportID;
    } else {
      await checklistV2FillService
        .createReport({ params })
        .then(response => {
          commit('setReport', { report: {}, pagination: {} });
          commit('setReport', { report: response.data, pagination: response.data.pagination });
          reportId = response.data._id;
        })
        .catch(e => {
          let errorMessage = '';
          if (e.response.data.data[0]) {
            errorMessage = e.response.data.data[0].msg;
          }
          commit('requestFailed');
          dispatch(
            'alert/error',
            {
              position: 'center',
              title: errorMessage,
              type: 'error',
              showType: 'dialog',
              text: 'Report has already been generated, please check in Draft or submitted'
            },
            { root: true }
          );
          if (redirectUrl !== '') {
            router.push(redirectUrl);
          }
          dispatch('common/handleServiceException', { e }, { root: true });
        });
    }

    dispatch('singleCheckpoint', { params: singleCheckpoint, reportId, router });
  },
  singleCheckpoint({ dispatch, commit }, { params, reportId, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setCheckpoint', { checkpointResponse: {}, pagination: {} });
    commit('startRequest');
    let abc = {
      checkpoints: params.checkpoints.map(checkpoint => ({
        ...checkpoint,
        report: reportId
      })),
      overallProgress: params.overallProgress,
      overallScore: params.overallScore,
      reportStatusColor: params?.reportStatusColor,
      expireUnits: params.expireUnits,
      expireSKU: params.expireSKU,
      instructionOrComment: params.instructionOrComment
    };

    checklistV2FillService
      .submitCheckpoint({ params: abc })
      .then(response => {
        dispatch(
          'alert/success',
          {
            position: 'center',
            showType: 'toast',
            text: 'Checkpoints saved successfully'
          },
          { root: true }
        );
        commit('setCheckpoint', { checkpointResponse: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getUserFilledCheckpoints({ dispatch, commit }, { reportId, router }) {
    dispatch('alert/clear', {}, { root: true });

    commit('setUserFilledCheckpoints', { userFilledCheckpoints: [] });

    commit('startRequest');
    checklistV2FillService
      .getSubmittedCheckpoints({ reportId })
      .then(response => {
        commit('setUserFilledCheckpoints', { userFilledCheckpoints: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  releaseReport({ dispatch, commit }, { params, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    checklistV2FillService
      .releaseReport({ params })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Report has been Updated'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getUserFilledReport({ dispatch, commit }, { router }) {
    dispatch('alert/clear', {}, { root: true });

    commit('setUserFilledReport', { userFilledReport: [] });
    commit('startRequest');
    checklistV2FillService
      .userFilledReports()
      .then(response => {
        commit('setUserFilledReport', { userFilledReport: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  checkSubmittedChecklistV2({ dispatch, commit }, { query, router }) {
    dispatch('alert/clear', {}, { root: true });

    commit('setLastMonthFilledChecklist', { lastMonthFilledChecklist: [] });
    commit('startRequest');
    checklistV2FillService
      .checkSubmittedChecklistV2({ query })
      .then(response => {
        commit('setLastMonthFilledChecklist', { lastMonthFilledChecklist: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteFoodSafetyReport({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    checklistV2FillService
      .deleteFoodSafetyReport({ id })
      .then(response => {
        // commit('setLastMonthFilledChecklist', { lastMonthFilledChecklist: response.data });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Report has been deleted.'
          },
          { root: true }
        );
        dispatch('listReleasedReports', { query: {} });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setSelectedStore(state, { storeSelected }) {
    state.storeSelected = storeSelected;
    state.loading = false;
  },
  setLastMonthFilledChecklist(state, { lastMonthFilledChecklist }) {
    state.lastMonthFilledChecklist = lastMonthFilledChecklist;
    // const abc = lastMonthFilledChecklist?.data?.checklistData;
    // state.lastMonthFilledChecklist = _.map(abc, lastRecord => {
    //   const newRecord = new LastMonthRecord({
    //     checkpointId: lastRecord?._id,
    //     auditObservation: lastRecord?.auditObservation,
    //     auditorsRecommendation: lastRecord?.auditorsRecommendation
    //   });
    //   return newRecord;
    // });
    state.loading = false;
  },
  setReport(state, { report }) {
    state.report = report;
    state.loading = false;
  },
  setUserFilledReport(state, { userFilledReport }) {
    state.userFilledReport = userFilledReport;
    state.loading = false;
  },
  setSingleReportData(state, { singleReportData }) {
    state.singleReportData = singleReportData;
    state.loading = false;
  },
  setUserFilledCheckpoints(state, { userFilledCheckpoints }) {
    state.userFilledCheckpoints = userFilledCheckpoints;
    state.loading = false;
  },
  setCheckpoint(state, { checkpointResponse }) {
    state.checkpointResponse = checkpointResponse;
    state.loading = false;
  },

  setReviewChecklistsV2(state, { reviewChecklistsV2 }) {
    state.reviewChecklistsV2 = reviewChecklistsV2;
    // state.pagination
    state.loading = false;
  },
  setReleasedReportsV2(state, { releasedReportsV2, pagination }) {
    state.releasedReportsV2 = releasedReportsV2.map((i, z) => {
      return {
        ...i,
        rowNum: z + 1
      };
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDownloadDashboardReport(state, { downloadDashboard }) {
    state.downloadDashboard = downloadDashboard.map((i, z) => {
      return {
        ...i,
        rowNum: z + 1
      };
    });
  },
  setDownloadReportsV2(state, { downloadReportsV2 }) {
    let row = 1;
    state.downloadReportsV2 = downloadReportsV2.map((report, index) => {
      const { _id } = report;
      const newReport = new ReportV2({
        id: _id,
        index,
        asmName: report?.store?.asmName,
        checklistName: report?.checklistName,
        storeName: report?.store?.storeName,
        storeId: report?.storeid,
        status:
          report.reportStatusColor === '#52bf90' ? 'Green' : report.reportStatusColor === '#fdf498' ? 'Yellow' : 'Red',
        score: report?.overallScore,
        expireUnits: report?.expireUnits,
        expireSku: report?.expireSKU,
        auditDate: moment(report.auditDate).format('DD-MM-YYYY'),
        region: report?.store?.region
      });
      row += 1;
      return newReport;
    });
    state.loading = false;
  },
  setChecklistsV2(state, { checklistsV2, pagination }) {
    state.checklistsV2 = _.map(checklistsV2, (checklistV2, index) => {
      const newChecklistV2 = new ChecklistV2({
        index,
        id: checklistV2._id, // eslint-disable-line
        name: checklistV2.name,
        active: checklistV2.active,
        startDate: checklistV2.startDate,
        endDate: checklistV2.endDate,
        storeids: checklistV2.storeids.join(','),
        clusters: checklistV2.clusters.join(','),
        regions: checklistV2.regions.join(','),
        formats: checklistV2.formats.join(','),
        shareTo: checklistV2.shareTo.map(shareTo => shareTo.email).join(','),
        permissions: checklistV2.permissions.map(permissions => permissions.name).join(','),
        roles: checklistV2.roles.map(roles => roles.name).join(','),
        frequency: checklistV2.frequency,
        activeTillTime: checklistV2.activeTillTime
      });
      return newChecklistV2;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setFillingCheckpoints(state, { fillingCheckpoints }) {
    state.fillingCheckpoints = fillingCheckpoints.map((checkpoint, index) => {
      return { ...checkpoint, index: index + 1 };
    });
    state.lastMonthFilledObservations = fillingCheckpoints.map(checkpoint => {
      return {
        checkpointId: checkpoint._id,
        auditObservation: 'Testing bro',
        auditorsRecommendation: 'This is for testing b r o'
      };
    });
    state.loading = false;
  },
  setKeyPointReports(state, { keyPointReports }) {
    state.keyPointReports = keyPointReports;
    state.loading = false;
  },
  setCategoriesAndDepartments(state, { categoriesAndDepartments }) {
    state.categoriesAndDepartments = {
      categories: categoriesAndDepartments.categories,
      departments: categoriesAndDepartments.departments
    };
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
