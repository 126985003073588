import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listBudget({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/budget-snapshot`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postBudget({ budget }) {
    return axios
      .post(`${configService.get('apiUrl')}/budget-snapshot`, budget)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async bulkBudgetUpload({ list }) {
    return axios
      .post(`${configService.get('apiUrl')}/budget-snapshot/upload`, list)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getOne({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/budget-snapshot/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteOneBudget({ id }) {
    return axios
      .delete(`${configService.get('apiUrl')}/budget-snapshot/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async patchOne({ id, newBudget }) {
    return axios
      .patch(`${configService.get('apiUrl')}/budget-snapshot/${id}`, newBudget)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listBudgetType() {
    let url = `${configService.get('apiUrl')}/budgetTypeList`;

    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listExpense({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate',
      'company'
    ]);
    let url = `${configService.get('apiUrl')}/expense-request`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async postExpense({ expense }) {
    return axios
      .post(`${configService.get('apiUrl')}/expense-request`, expense)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getOneExpense({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/expense-request/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async patchOneExpense({ id, newExpense }) {
    return axios
      .patch(`${configService.get('apiUrl')}/expense-request/${id}`, newExpense)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listRules() {
    let url = `${configService.get('apiUrl')}/rules`;

    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getSignedUrl({ filename, filetype }) {
    return axios
      .get(
        `${configService.get('apiUrl')}/expense-request/get-signed-url?filename=${filename}&filetype=${filetype}`,
        {}
      )
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async uploadImage({ url, file }) {
    const instance = axios.create();
    delete instance.defaults.headers.common.Authorization;
    const fileType = file.type;
    const config = {
      method: 'put',
      url,
      headers: {
        'Content-Type': fileType
      },
      data: file
    };
    return instance(config)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listTransaction() {
    let url = `${configService.get('apiUrl')}/transaction`;

    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getSummary({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/pattyCashSummary?storeId=${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listBudgetIncreaseRequest({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/budgetIncrease`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async postBudgetIncreaseRequest({ request }) {
    return axios
      .post(`${configService.get('apiUrl')}/budgetIncrease`, request)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async patchMakeAllBudgetLive({ list }) {
    return axios
      .patch(`${configService.get('apiUrl')}/budget-snapshot/approve`, list)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async patchBudgetIncrease({ id, request }) {
    return axios
      .patch(`${configService.get('apiUrl')}/budgetIncrease/${id}`, request)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listCashSnapshot({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/pattyCashSnapshot`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listCashSnapshot_Transaction({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/pattyCashSnapshot-listCashTransaction`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postCashSnapshot({ request }) {
    return axios
      .post(`${configService.get('apiUrl')}/pattyCashSnapshot`, request)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postAddCash({ addition }) {
    return axios
      .post(`${configService.get('apiUrl')}/pattyCashSnapshot-addCash`, addition)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getBudgetSummary({}) {
    return axios
      .get(`${configService.get('apiUrl')}/pattyCashSummary`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async storeWiseCashTransaction({ query = {}, storeId } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/storeWiseCashTransaction?store=${storeId}`;
    if (pickedQuery) {
      url += `&${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async bulkCashUpload({ list }) {
    return axios
      .post(`${configService.get('apiUrl')}/pattyCashSnapshot-bulkAddCash`, list)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async bulkCreateAccount({ list }) {
    return axios
      .post(`${configService.get('apiUrl')}/pattyCashSnapshot-bulkAccountCreation`, list)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async generateOtp() {
    let url = `${configService.get('apiUrl')}/generate-otp`;
    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async verifyOtp({ otp }) {
    let url = `${configService.get('apiUrl')}/verify-otp/${otp}`;
    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async patchMakeAllExpenseLive({ list }) {
    return axios
      .patch(`${configService.get('apiUrl')}/multi-approve-expense-request`, list)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async patchEditExpenseList({ list }) {
    return axios
      .patch(`${configService.get('apiUrl')}/multi-edit-expense-request`, list)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async pettyCashReport({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'startdate', 'enddate']);
    let url = `${configService.get('apiUrl')}/getPettyCashReport`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
