import axios from 'axios';
import configService from '@/services/configService';

export default {
  async listComment() {
    const url = `${configService.get('apiUrl')}/comments`;

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postReportComment({ comment, reportId } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/report/comment/${reportId}`, { comment })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postNoteComment({ comment, noteId } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/notes/comment/${noteId}`, { comment })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postEventComment({ comment, eventId } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/event-photos/comment/${eventId}`, { comment })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
