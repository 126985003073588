export default class MIS {
  constructor({ row, Zone, Region, StoreId, StoreName, checklistName, Frequency, Format, AmRemark, Remark }) {
    this.row = row;
    this.Zone = Zone;
    this.Region = Region;
    this.StoreId = StoreId;
    this.StoreName = StoreName;
    this.checklistName = checklistName;
    this.Frequency = Frequency;
    this.Format = Format;
    this.AmRemark = AmRemark;
    this.Remark = Remark;
  }
}
