import moment from 'moment';

export default class User {
  static userEnabled = {
    active: 1,
    disabled: 0
  };

  static nameUserStatus = {
    1: 'Active',
    0: 'Not Active'
  };

  static userRole = {
    administrator: 99,
    staff: 50,
    caller: 25,
    user: 1
  };

  static nameUserRole = {
    99: 'Administrator',
    50: 'Store Staff',
    25: 'Outbound Caller',
    1: 'User'
  };

  constructor({
    rowNum,
    id,
    mobile,
    name,
    roles,
    email,
    status,
    empid,
    password,
    company,
    designation,
    storeid,
    storeids,
    clusters,
    regions,
    shouldBeNotified,
    lastLogin,
    pincode,
    nearbyPincode,
    storeManagerNumber,
    smsNumber
  }) {
    this.rowNum = rowNum || null;
    this.id = id;
    this.mobile = mobile;
    this.company = company || null;
    this.name = name;
    this.roles = roles;
    this.status = status;
    this.accessto = [...storeids, ...clusters, ...regions].join(', ');
    this.lastLogin = lastLogin ? moment(lastLogin).format('LLL') : null;
    this.empid = empid || null;
    this.email = email;
    this.designation = designation || null;
    this.password = password;
    this.storeid = storeid || null;
    this.storeids = storeids;
    this.clusters = clusters;
    this.shouldBeNotified = shouldBeNotified;
    this.regions = regions || [];

    this.pincode = pincode;
    this.nearbyPincode = nearbyPincode;
    this.storeManagerNumber = storeManagerNumber;
    this.smsNumber = smsNumber;
    this.expand();
  }

  expand() {
    this.roleName = User.nameUserRole[this.role];
    this.statusName = User.nameUserStatus[this.status];
  }
}
