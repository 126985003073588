import _ from 'lodash';
import brandService from '@/services/brandService';
import Brand from '../../model/brand';

const state = {
  baseUrl: '/localBrand',
  brandList: [],
  downloadBrandList: [],
  pagination: {},
  laoding: false
};

const actions = {
  listBrand({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setBrands', { brands: [], pagination: {} });

    commit('startRequest');


    brandService
      .listBrand({ query })
      .then(response => {
        commit('setBrands', { brands: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  upload({ dispatch, commit }, { data, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    brandService
      .upload({ data })
      .then(_response => {
        commit('finishRequest');
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            type: 'success',
            text: 'Brand Items uploaded successfully !!'
          },
          { root: true }
        );
        router.go();
      })
      .catch(e => {
        let errorMessage = '';
        if (e.response.data.data[0]) {
          errorMessage = e.response.data.data[0].msg;
        }
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: errorMessage,
            type: 'error',
            showType: 'dialog',
            text: 'Upload failed, remember to use only sample file provided and check the line number !!'
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e }, { root: true });
      });
    commit('finishRequest');
  },
  download({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadBrandList', { downloadBrandList: [] });

    brandService
      .listBrand({ query })
      .then(response => {
        commit('setDownloadBrandList', {
          downloadBrandList: response.data.rows
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          { e },
          {
            root: true
          }
        );
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadBrandList', { downloadBrandList: [] });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    brandService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listBrand', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Brand Item has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setBrands(state, { brands, pagination }) {
    let row = 1;
    state.brandList = brands.map(brand => {
      const { _id } = brand;
      const newBrand = new Brand({
        id: _id,
        rowNum: row,
        storeid: brand.storeid,
        storeName: brand.storeName,
        cityName: brand.cityName,
        itemName: brand.itemName,
        brandName: brand.brandName,
        description: brand.description,
        mrp: brand.mrp,
        localSellingPrice: brand.localSellingPrice,
        category: brand.category,
        submittedByName: brand.submittedByName
      });
      row += 1;
      return newBrand;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDownloadBrandList(state, { downloadBrandList }) {
    let row = 1;
    state.downloadBrandList = _.map(downloadBrandList, brand => {
      const { _id } = brand;
      const formattedBrand = new Brand({
        id: _id,
        rowNum: row,
        storeid: brand.storeid,
        storeName: brand.storeName,
        cityName: brand.cityName,
        itemName: brand.itemName,
        brandName: brand.brandName,
        description: brand.description,
        mrp: brand.mrp,
        localSellingPrice: brand.localSellingPrice,
        category: brand.category,
        submittedByName: brand.submittedByName
      });
      row += 1;
      return formattedBrand;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
