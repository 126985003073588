import reportService from '@/services/reportService';
import Report from '../../model/report';

const state = {
  baseUrl: '/report',
  pagination: {},
  reportList: [],
  downloadReportList: [],
  report: null,
  comment: null,
  loading: true
};

const actions = {
  getOne({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setReport', { report: null });

    reportService
      .getOne({ query })
      .then(response => {
        commit('setReport', { report: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listReport({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setReports', { reports: [], pagination: {} });

    commit('startRequest');

    reportService
      .listReport({ query })
      .then(response => {
        commit('setReports', { reports: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  download({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadReportList', { downloadReportList: [] });

    reportService
      .listReport({ query })
      .then(response => {
        commit('setDownloadReportList', {
          downloadReportList: response.data.rows,
          pagination: response.data.pagination
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadReportList', { downloadReportList: [] });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setReport(state, { report }) {
    state.report = report;
    state.loading = false;
  },
  setReports(state, { reports, pagination }) {
    let row = 1;
    state.reportList = reports
      .filter(report => report.checklist)
      .map(report => {
        const { _id } = report;
        const newReport = new Report({
          rowNum: row,
          id: _id,
          storeName: report.store ? report.store.storeName : '',
          storeid: report.store ? report.store.storeid : report.storeid,
          cluster: report.store ? report.store.cluster : '',
          region: report.store ? report.store.region : '',
          format: report.store ? report.store.format : '',
          checkListName: report.checklist.name ? report.checklist.name : '',
          completed: report.completed ? report.completed : '',
          overallScore: report.overallScore ? report.overallScore : '',
          overallProgress: report.overallProgress ? report.overallProgress : '',
          comments: report.comments ? report.comments : '',
          submittedBy: report.submittedByName ? report.submittedByName : '',
          createdAt: report.createdAt ? report.createdAt : '',
          location: report.location ? report.location : '',
          updatedAt: report.updatedAt ? report.updatedAt : ''
        });
        row += 1;
        return newReport;
      });
    state.pagination = pagination;
    state.loading = false;
  },
  setComment(state, { comment }) {
    state.comment = new Report({
      id: comment._id, // eslint-disable-line
      comments: comment.comments
    });
    state.loading = false;
  },
  setDownloadReportList(state, { downloadReportList }) {
    let row = 1;
    state.downloadReportList = downloadReportList
      .filter(report => report.checklist)
      .map(report => {
        const { _id } = report;
        const newReport = new Report({
          rowNum: row,
          id: _id,
          storeName: report.store ? report.store.storeName : '',
          storeid: report.store ? report.store.storeid : report.storeid,
          cluster: report.store ? report.store.cluster : '',
          region: report.store ? report.store.region : '',
          format: report.store ? report.store.format : '',
          checkListName: report.checklist.name ? report.checklist.name : '',
          completed: report.completed ? report.completed : '',
          overallScore: report.overallScore ? report.overallScore : '',
          comments: report.comments ? report.comments : '',
          submittedBy: report.submittedByName ? report.submittedByName : '',
          updatedAt: report.updatedAt ? report.updatedAt : '',
          location: report.location
        });
        row += 1;
        return newReport;
      });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
