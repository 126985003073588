import _ from 'lodash';
import dashChartService from '@/services/dashChartService';
import DashChart from '../../model/dashChart';

const state = {
  baseUrl: '/dashboard/pendingchecklistbyuser',
  nameChart: []
};

const actions = {
  chartListSecond({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setNameChart', { nameChart: [] });

    dashChartService
      .list({ query })
      .then(response => {
        commit('setNameChart', {
          nameChart: response.data
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  finishRequest(state) {
    state.loading = false;
  },
  setNameChart(state, { nameChart }) {
    let row = 1;
    state.nameChart = _.map(nameChart, dashChart => {
      const newDashChart = new DashChart({
        row,
        submittedcount: dashChart.submittedcount,
        pendingcount: dashChart.pendingcount,
        checklistName: dashChart.checklistName
      });
      row += 1;
      return newDashChart;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
