import moment from 'moment';

export default class ChecklistFill {
  constructor({
    id,
    rowNum,
    storeName,
    storeid,
    cluster,
    region,
    format,
    checkListName,
    completed,
    submittedBy,
    submittedByName,
    createdAt,
    location,
    overallScore,
    overallProgress,
    comments,
    updatedAt,
    checklistID
  }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.checkListName = checkListName || '';
    this.submittedBy = submittedBy || '';
    this.submittedByName = submittedByName || '';
    this.completed = completed ? 'True' : 'False';
    this.storeName = storeName || '';
    this.storeid = storeid || '';
    this.cluster = cluster || '';
    this.region = region || '';
    this.format = format || '';
    this.overallScore = overallScore || '';
    this.location = location || '';
    this.overallProgress = overallProgress || '';
    this.comments = comments || '';
    this.updatedAt = updatedAt ? updatedAt : null;
    this.createdAt = createdAt ? createdAt : null;
    this.checklistID = checklistID || '';
  }
}
