import moment from 'moment';

export default class CustomPopPrint {
  constructor({
    index,
    articleCode,
    articleDescription,
    family,
    mrp,
    pop1,
    offerPrice,
    percentageOff,
    rupeesOff,
    popPaperType,
    isPrinted,
    validTo,
    brick,
    offerCommunication
  }) {
    this.index = index;
    this.articleCode = articleCode;
    this.articleDescription = articleDescription;
    this.family = family;
    this.mrp = mrp;
    this.pop1 = pop1;
    this.offerPrice = offerPrice;
    this.percentageOff = percentageOff;
    this.rupeesOff = rupeesOff;
    this.popPaperType = popPaperType;
    this.validTo = validTo || moment().format('MM-DD-YYYY');
    this.brick = brick;
    this.isPrinted = isPrinted || false;
    this.offerCommunication = offerCommunication || pop1;
  }
}
