import moment from 'moment';

export default class KYCInstitutional {
  constructor({
    id,
    rowNum,
    customerName,
    mobile,
    email,
    designation,
    organisationName,
    address,
    pinCode,
    favouriteStore,
    numberOfEmployees,
    approxBudgetPerEmployee,
    remarks,
    submittedByName,
    createdAt,
    roles,
    storeids
  }) {
    this.id = id;
    this.rowNum = rowNum || '';
    this.customerName = customerName || 'NA';
    this.mobile = mobile || 'NA';
    this.email = email || 'NA';
    this.designation = designation || 'NA';
    this.organisationName = organisationName || 'NA';
    this.address = address || 'NA';
    this.pinCode = pinCode || 'NA';
    this.favouriteStore = favouriteStore || 'NA';
    this.numberOfEmployees = numberOfEmployees || 'NA';
    this.approxBudgetPerEmployee = approxBudgetPerEmployee || 'NA';
    this.remarks = remarks || 'NA';
    this.submittedByName = submittedByName || '';
    this.roles = roles || '';
    this.storeids = storeids || '';
    this.createdAt = createdAt ? moment(createdAt).format('LL') : null;
  }
}
