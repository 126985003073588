import moment from 'moment';

export default class Report {
  constructor({
    id,
    rowNum,
    storeName,
    storeid,
    cluster,
    region,
    format,
    checkListName,
    completed,
    submittedBy,
    createdAt,
    location,
    overallScore,
    overallProgress,
    comments,
    updatedAt
  }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.checkListName = checkListName || '';
    this.submittedBy = submittedBy || '';
    this.completed = completed ? 'True' : 'False';
    this.storeName = storeName || '';
    this.storeid = storeid || '';
    this.cluster = cluster || '';
    this.region = region || '';
    this.format = format || '';
    this.overallScore = overallScore || '';
    this.updatedAt = updatedAt ? moment(updatedAt).format('DD-MM-YYYY hh:mm  A') : null;
    this.location =
      location.lat && location.long ? `https://maps.google.com/?q=${location.lat},${location.long}` : null;
    this.overallProgress = overallProgress || '';
    this.comments = comments || '';
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY') : null;
  }
}
