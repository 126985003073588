export default class Dashboard {
  constructor({ storeid, storename, cluster, region, count, submittedCount, pendingCount }) {
    this.storeid = storeid || '';
    this.storename = storename || '';
    this.cluster = cluster || '';
    this.region = region || '';
    this.count = count || '';
    this.submittedCount = submittedCount || '';
    this.pendingCount = pendingCount || '';
  }
}
