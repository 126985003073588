import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listStore({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q']);
    let url = `${configService.get('apiUrl')}/setting`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async getStoreData({ storeId }) {
    return axios
      .get(`${configService.get('apiUrl')}/setting/${storeId}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async patchStoreData({
    storeId,
    pincodes,
    managerNumbers,
    smsNumber,
    updateManagerNumber
  }) {
    return axios
      .patch(`${configService.get('apiUrl')}/setting/${storeId}`, {
        pincodes,
        managerNumbers,
        smsNumber,
        updateManagerNumber
      })
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
