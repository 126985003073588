import moment from 'moment';

export default class Barcode {
  constructor({
    id,
    rowNum,
    barcode,
    storeid,
    articleCode,
    articleName,
    systemCount,
    physicalCount,
    category,
    group,
    submittedByName,
    createdAt,
    updatedAt,
    scannedByName,
    scannedByDesignation,
    scannedByDepartment
  }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.barcode = barcode || '';
    this.storeid = storeid || '';
    this.articleCode = articleCode || '';
    this.articleName = articleName || '';
    this.systemCount = systemCount || 0;
    this.physicalCount = physicalCount || 0;
    this.category = category || '';
    this.group = group || '';
    this.submittedByName = submittedByName || '';
    this.createdAt = createdAt ? moment(createdAt).utcOffset('+05:30').format('DD-MM-YYYY HH:mm:ss') : '';
    this.updatedAt = updatedAt ? moment(updatedAt).utcOffset('+05:30').format('DD-MM-YYYY hh:mm:ss') : null;
    this.scannedByName = scannedByName || '';
    this.scannedByDesignation = scannedByDesignation || '';
    this.scannedByDepartment = scannedByDepartment || '';
  }
}
