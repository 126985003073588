import _ from 'lodash';
import Vue from 'vue';
import Checklist from '../../model/checklist';
import ChecklistFill from '../../model/checklistFill';
import checklistFillService from '@/services/checklistFillService';

const state = {
  baseUrl: '/checklist-fill',
  availableChecklists: [],
  userFilledChecklists: [],
  pagination: {},
  loading: false,
  storeSelected: localStorage.getItem('storeId') || '',
  report: {},
  checkpointResponse: [],
  fillingCheckpoints: [],
  userFilledReport: []
};

const actions = {
  list({ dispatch, commit }, { storeId, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setChecklists', { availableChecklists: [], pagination: {} });
    commit('startRequest');
    checklistFillService
      .list({ storeId })
      .then(response => {
        commit('setChecklists', { availableChecklists: response.data, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  selectStore({ dispatch, commit }, { storeId }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setSelectedStore', { storeSelected: '', pagination: {} });
    commit('setSelectedStore', { storeSelected: storeId });
  },

  userReports({ dispatch, commit }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setUserReports', { userFilledChecklists: [], pagination: {} });
    commit('startRequest');
    checklistFillService
      .getUserSavedReport()
      .then(response => {
        commit('setUserReports', { userFilledChecklists: response.data, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },

  async generateReport({ dispatch, commit }, { reportID, params, singleCheckpoint, router, redirectUrl }) {
    let reportId;
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    if (reportID) {
      reportId = reportID;
    } else {
      await checklistFillService
        .createReport({ params })
        .then(response => {
          commit('setReport', { report: {}, pagination: {} });
          commit('setReport', { report: response.data, pagination: response.data.pagination });
          reportId = response.data._id;
        })
        .catch(e => {
          let errorMessage = '';
          if (e.response.data.data[0]) {
            errorMessage = e.response.data.data[0].msg;
          }
          commit('requestFailed');
          dispatch(
            'alert/error',
            {
              position: 'center',
              title: errorMessage,
              type: 'error',
              showType: 'dialog',
              text: 'Report has already been generated, please check in Draft or submitted'
            },
            { root: true }
          );
          if (redirectUrl !== '') {
            router.push(redirectUrl);
          }
          dispatch('common/handleServiceException', { e }, { root: true });
        });
    }

    dispatch('singleCheckpoint', { params: singleCheckpoint, reportId, router });
  },
  singleCheckpoint({ dispatch, commit }, { params, reportId, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setCheckpoint', { checkpointResponse: {}, pagination: {} });

    let abc = {
      checkpoints: params.checkpoints.map(checkpoint => ({
        ...checkpoint,
        report: reportId
      })),
      overallProgress: params.overallProgress,
      overallScore: params.overallScore
    };

    checklistFillService
      .submitCheckpoint({ params: abc })
      .then(response => {
        commit('setCheckpoint', { checkpointResponse: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getCheckpoints({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setFillingCheckpoints', { fillingCheckpoints: [], pagination: {} });

    commit('startRequest');
    checklistFillService
      .checkpoints({ id })
      .then(response => {
        commit('setFillingCheckpoints', {
          fillingCheckpoints: response.data,
          pagination: response.data.pagination
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getUserFilledReport({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setUserFilledReport', { userFilledReport: [] });

    checklistFillService
      .userFilledReports({ query })
      .then(response => {
        commit('setUserFilledReport', { userFilledReport: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { params, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    checklistFillService
      .patchReport({ params })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Checklist has been submitted.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setUserFilledReport(state, { userFilledReport }) {
    state.userFilledReport = userFilledReport;
    state.loading = false;
  },
  setCheckpoint(state, { checkpointResponse }) {
    state.checkpointResponse = checkpointResponse;
    state.loading = false;
  },
  setReport(state, { report }) {
    state.report = report;
    state.loading = false;
  },
  setSelectedStore(state, { storeSelected }) {
    state.storeSelected = storeSelected;
    state.loading = false;
  },
  setFillingCheckpoints(state, { fillingCheckpoints }) {
    state.fillingCheckpoints = fillingCheckpoints.map((checkpoint, index) => {
      return { ...checkpoint, index: index + 1 };
    });
    state.loading = false;
  },
  setChecklists(state, { availableChecklists, pagination }) {
    state.availableChecklists = _.map(availableChecklists, (checklist, index) => {
      const newChecklist = new Checklist({
        index,
        id: checklist._id, // eslint-disable-line
        name: checklist.name,
        active: checklist.active,
        startDate: checklist.startDate,
        endDate: checklist.endDate,
        storeids: checklist.storeids.join(','),
        clusters: checklist.clusters.join(','),
        regions: checklist.regions.join(','),
        formats: checklist.formats.join(','),
        shareTo: checklist.shareTo.map(shareTo => shareTo.email).join(','),
        permissions: checklist.permissions.map(permissions => permissions.name).join(','),
        roles: checklist.roles.map(roles => roles.name).join(','),
        frequency: checklist.frequency,
        activeTillTime: checklist.activeTillTime,
        updatedAt: checklist.updatedAt,
        createdAt: checklist.createdAt
      });
      return newChecklist;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setUserReports(state, { userFilledChecklists }) {
    state.userFilledChecklists = userFilledChecklists
      .filter(report => report.checklist && report.submittedBy)
      .map((report, index) => {
        const { _id } = report;
        const filledChecklist = new ChecklistFill({
          rowNum: index + 1,
          id: _id,
          storeName: report.store ? report.store.storeName : '',
          storeid: report.store ? report.store.storeid : report.storeid,
          cluster: report.store ? report.store.cluster : '',
          region: report.store ? report.store.region : '',
          format: report.store ? report.store.format : '',
          checkListName: report.checklist.name ? report.checklist.name : '',
          completed: report.completed ? report.completed : '',
          overallScore: report.overallScore ? report.overallScore : '',
          overallProgress: report.overallProgress ? report.overallProgress : '',
          comments: report.comments ? report.comments : '',
          submittedBy: report.submittedBy.name ? report.submittedBy.name : '',
          submittedByName: report.submittedByName ? report.submittedByName : '',
          createdAt: report.createdAt ? report.createdAt : '',
          location: report.location ? report.location : '',
          updatedAt: report.updatedAt ? report.updatedAt : '',
          checklistID: report.checklist._id ? report.checklist._id : ''
        });

        return filledChecklist;
      });
    state.loading = false;
  },
  setFillingCheckpoints(state, { fillingCheckpoints }) {
    state.fillingCheckpoints = fillingCheckpoints.map((checkpoint, index) => {
      return { ...checkpoint, rowNum: index + 1 };
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
