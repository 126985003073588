import moment from 'moment';

export default class Gift {
  constructor({ id, rowNum, giftPromoId, giftName, storeid, billNumber, tillNumber, billAmount, customerMobile, billDate, submittedByName, createdAt }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.storeid = storeid || '';
    this.billNumber = billNumber || '';
    this.tillNumber = tillNumber || '';
    this.billAmount = billAmount || '';
    this.customerMobile = customerMobile || '';
    this.billDate = billDate ? moment(billDate).format('DD-MM-YYYY') : null;
    this.giftPromoId = giftPromoId || '';
    this.giftName = giftName || '';
    this.submittedByName = submittedByName || '';
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY') : null;

  }
}
