import _ from 'lodash';
import storeService from '@/services/storeService';
import Store from '../../model/store';

const state = {
  baseUrl: '/store',
  storeList: [],
  regionList: [],
  clusterList: [],
  formatList: [],
  storeByUser: [],
  store: null,
  loading: false
};

const actions = {
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setStore', { store: {} });

    storeService
      .getOne({ id })
      .then(response => {
        commit('setStore', { store: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { type = 'store', id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    storeService
      .deleteOne({ type, id })
      .then(_response => {
        commit('deleteStore', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'bottom-end',
            title: '',
            text: type === 'store' ? 'Store has been deleted.' : 'Staff has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { type = 'store', store, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    storeService
      .postOne({ type, store })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'bottom-end',
            title: '',
            text: type === 'store' ? 'New store has been added.' : 'New staff has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listStore({ dispatch, commit }, { router, query }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setStores', { storeList: [] });
    commit('startRequest');
    storeService
      .listStore({ query })
      .then(response => {
        commit('setStores', { storeList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listByUser({ dispatch, commit }, { router, query }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setStoresUser', { storeByUser: [] });
    commit('startRequest');

    storeService
      .listByUser({ query })
      .then(response => {
        commit('setStoresUser', { storeByUser: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listFormat({ dispatch, commit }, { router, query }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setFormats', { formatList: [] });

    storeService
      .listStore({ query })
      .then(response => {
        commit('setFormats', { formatList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listCluster({ dispatch, commit }, { router, query }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setClusters', { clusterList: [] });

    storeService
      .listStore({ query })
      .then(response => {
        commit('setClusters', { clusterList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  listRegion({ dispatch, commit }, { router, query }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setRegions', { regionList: [] });

    storeService
      .listStore({ query })
      .then(response => {
        commit('setRegions', { regionList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, type = 'store', store, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    storeService
      .patchOne({ id, type, store })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Store has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  deleteStore(state, { id }) {
    /* eslint no-underscore-dangle: 0 */
    state.storeList = state.storeList.filter(i => i._id !== id);
    state.loading = false;
  },
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setStores(state, { storeList }) {
    if (storeList.length) {
      // const storeOptions = storeList.map(store => ({ text: store, value: store }))
      state.storeList = storeList;
    }
    state.loading = false;
  },
  setStoresUser(state, { storeByUser }) {
    if (storeByUser.length) {
      // const storeOptions = storeList.map(store => ({ text: store, value: store }))
      state.storeByUser = storeByUser;
    }
    state.loading = false;
  },
  setStore(state, { store }) {
    const { _id } = store;
    state.store = new Store({
      id: _id,
      storeName: store.storeName,
      storeid: store.storeid,
      cluster: store.cluster,
      region: store.region,
      format: store.format,
      companyCode: store.companyCode,
      storeManagerEmail: store.storeManagerEmail,
      costCenter: store.costCenter,
      pattyCashStoreGl: store.pattyCashStoreGl,
      asmMobile: store.asmMobile,
      asmName: store.asmName,
      city: store.city,
      company: store.company,
      rsmMobile: store.rsmMobile,
      rsmName: store.rsmName,
      storeManagerMobile: store.storeManagerMobile,
      storeManagerName: store.storeManagerName,
      tradingArea: store.tradingArea,
      pettyCashCompanyName: store.pettyCashCompanyName,
      asmEmail: store.asmEmail,
      businessManagerEmail: store.businessManagerEmail,
      regionalAuditorEmail: store.regionalAuditorEmail,
      regionalQualityManagerEmail: store.regionalQualityManagerEmail,
      regionalLpManagerEmail: store.regionalLpManagerEmail
    });
    state.loading = false;
  },
  setClusters(state, { clusterList }) {
    if (clusterList.length) {
      const cluster = clusterList.map(store => ({ text: store, value: store }));
      state.clusterList = cluster;
    }
    state.loading = false;
  },
  setRegions(state, { regionList }) {
    if (regionList.length) {
      const region = regionList.map(store => ({ text: store, value: store }));
      state.regionList = region;
    }
    state.loading = false;
  },
  setFormats(state, { formatList }) {
    if (formatList.length) {
      state.formatList = formatList.map(item => ({ text: item, value: item }));
    }
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
