import tagsService from '../../services/tagsService';
import Tags from '../../model/tags';

const state = {
  baseUrl: '/tags',
  tags: [],
  tagList: [],
  loading: false,
  tag: null
};

const actions = {
  listTags({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });

    commit('setTags', { tags: [] });
    commit('setTagList', { tagList: [] });
    commit('startRequest');

    tagsService
      .listTags({ query })
      .then(response => {
        commit('setTags', { tags: response.data });
        commit('setTagList', { tagList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setTag', { tag: {} });
    tagsService
      .getOne({ id })
      .then(response => {
        commit('setTag', { tag: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { tag, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    tagsService
      .postOne({ tag })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Tag has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, tag, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    tagsService
      .patchOne({ id, tag })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'bottom-end',
            title: '',
            text: 'Permission has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    tagsService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listTags', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Tag has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setTagList(state, { tagList }) {
    let row = 1;
    state.tagList = tagList.map(tag => {
      const { _id } = tag;
      const newTag = new Tags({
        rowNum: row,
        id: _id,
        tags: tag.tags,
        tagCategory: tag.tagCategory,
        createdAt: tag.createdAt
      });
      row += 1;
      return newTag;
    });
    state.loading = false;
  },
  setTags(state, { tags }) {
    let row = 1;
    state.tags = tags.map(tag => {
      const { _id } = tag;
      const newTag = new Tags({
        rowNum: row,
        id: _id,
        tags: tag.tags.join(','),
        tagCategory: tag.tagCategory,
        createdAt: tag.createdAt
      });
      row += 1;
      return newTag;
    });
    state.loading = false;
  },
  setTag(state, { tag }) {
    state.tag = new Tags({
      id: tag._id, // eslint-disable-line
      tags: tag.tags,
      tagCategory: tag.tagCategory
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
