import moment from 'moment';

export class ChecklistV2 {
  constructor({
    id,
    index,
    name,
    active,
    startDate,
    endDate,
    storeids,
    categories,
    departments,
    clusters,
    regions,
    permissions,
    roles,
    frequency,
    notifyTo,
    formats,
    checkpoints,
    checkpointType,
    checkpointsDeleted,
    checkpointsAdded,
    shareTo,
    activeTillTime,
    createdAt,
    updatedAt
  }) {
    this.id = id || '';
    this.index = index + 1;
    this.name = name || '';
    this.active = active ? 'True' : 'False';
    this.startDate = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
    this.endDate = endDate ? moment(endDate).format('YYYY-MM-DD') : null;
    this.regions = regions || '';
    this.clusters = clusters || '';
    this.formats = formats || '';
    this.storeids = storeids || '';
    this.categories = categories || '';
    this.departments = departments || '';
    this.roles = roles || '';
    this.frequency = frequency || '1';
    this.shareTo = shareTo || '';
    this.permissions = permissions || '';
    this.notifyTo = notifyTo || '';
    this.checkpoints = checkpoints || '';
    this.checkpointType = checkpointType || 'TARGET';
    this.checkpointsDeleted = checkpointsDeleted || '';
    this.checkpointsAdded = checkpointsAdded || '';
    this.activeTillTime = activeTillTime || '';
    this.updatedAt = updatedAt ? updatedAt : null;
    this.createdAt = createdAt ? createdAt : null;
  }
}
export class LastMonthRecord {
  constructor({ checkpointId, auditObservation, auditorsRecommendation }) {
    this.checkpointId = checkpointId || '';
    this.auditObservation = auditObservation || '';
    this.auditorsRecommendation = auditorsRecommendation || '';
  }
}

export class ReportV2 {
  constructor({
    id,
    index,
    checklistName,
    storeId,
    status,
    storeName,
    asmName,
    expireUnits,
    expireSku,
    score,
    auditDate,
    region
  }) {
    this.id = id || '';
    this.index = index + 1;
    this.checklistName = checklistName || '';
    this.storeId = storeId || '';
    this.storeName = storeName || '';
    this.region = region || '';
    this.asmName = asmName || '';
    this.score = score || '';
    this.status = status || '';
    this.expireUnits = expireUnits || '';
    this.expireSku = expireSku || '';
    this.auditDate = auditDate || '';
  }
}
