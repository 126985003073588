import moment from 'moment';

export default class comment {
  constructor({ id, updatedAt, body, rowNum, docType, index = 0, tag }) {
    this.id = id;
    this.rowNum = rowNum || '';
    this.body = body;
    this.index = index + 1;
    this.docType = docType;
    this.updatedAt = updatedAt ? moment(updatedAt).format('LLL') : null;
    this.tag = tag || '';
  }
}
