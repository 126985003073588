import _ from 'lodash';
import photoSharingService from '@/services/photoSharingService';
import PhotoSharing from '../../model/photoSharing';

const state = {
  baseUrl: '/photo-sharing-event',
  pagination: {},
  eventphotos: [],
  report: [],
  loading: false,
  appliedFilter: {},
  eventphoto: { image: [] }
};

const actions = {
  setFilter({ commit }, { filter }) {
    commit('setFilter', { filter });
  },
  listPhoto({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setEventphotos', { eventphotos: [], pagination: {} });

    commit('startRequest');

    photoSharingService
      .listPhoto({ query })
      .then(response => {
        commit('setEventphotos', {
          eventphotos: response.data.rows,
          pagination: response.data.pagination
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          { e, router },
          {
            root: true
          }
        );
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setEventphoto', { eventphoto: {} });

    photoSharingService
      .getOne({ id })
      .then(response => {
        commit('setEventphoto', { eventphoto: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          { e, router },
          {
            root: true
          }
        );
      });
  },
  downloadAll({ dispatch, commit }, { images }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    photoSharingService.downloadAll({ images });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    photoSharingService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listPhoto', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Event Photos has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          { e, router },
          {
            root: true
          }
        );
      });
  },
  downloadEventReport({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    dispatch(
      'alert/success',
      {
        showType: 'toast',
        position: 'center',
        title: '',
        text: ' Preparing your download.'
      },
      { root: true }
    );
    photoSharingService
      .downloadEventReport({ query })
      .then(response => {
        commit('setReport', { report: response.data.rows });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          { e, router },
          {
            root: true
          }
        );
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setEventphotos(state, { eventphotos, pagination }) {
    state.eventphotos = _.map(eventphotos, (eventphotos, index) => {
      const newPhotoSharing = new PhotoSharing({
        index,
        id: eventphotos._id, // eslint-disable-line
        eventName: eventphotos.eventName,
        storeid: eventphotos.storeid,
        storeName: eventphotos.storeName,
        submittedByName: eventphotos.submittedByName,
        createdAt: eventphotos.createdAt,
        updatedAt: eventphotos.updatedAt,
        submittedBy: eventphotos.submittedBy,
        image: eventphotos.image
      });
      return newPhotoSharing;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setEventphoto(state, { eventphoto }) {
    state.eventphoto = eventphoto;
    state.loading = false;
  },
  setReport(state, { report }) {
    state.report = report;
    state.loading = false;
  },
  setFilter(state, { filter }) {
    state.appliedFilter = filter;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
