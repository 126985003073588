import moment from 'moment';

export default class Checklist {
  constructor({
    id,
    index,
    name,
    active,
    startDate,
    endDate,
    storeids,
    clusters,
    regions,
    permissions,
    roles,
    frequency,
    notifyTo,
    formats,
    checkpoint,
    checkpointType,
    isCritical,
    checkpointsDeleted,
    checkpointsAdded,
    shareTo,
    activeTillTime,
    createdAt,
    updatedAt
  }) {
    this.id = id || '';
    this.index = index + 1;
    this.name = name || '';
    this.active = active ? 'True' : 'False';
    this.startDate = startDate ? moment(startDate).format('YYYY-MM-DD') : null;
    this.endDate = endDate ? moment(endDate).format('YYYY-MM-DD') : null;
    this.regions = regions || '';
    this.clusters = clusters || '';
    this.formats = formats || '';
    this.storeids = storeids || '';
    this.roles = roles || '';
    this.frequency = frequency || '1';
    this.shareTo = shareTo || '';
    this.permissions = permissions || '';
    this.notifyTo = notifyTo || '';
    this.checkpoint = checkpoint || '';
    this.checkpointType = checkpointType || 'TARGET';
    this.isCritical = isCritical ? 'True' : 'False';
    this.checkpointsDeleted = checkpointsDeleted || '';
    this.checkpointsAdded = checkpointsAdded || '';
    this.activeTillTime = activeTillTime || '';
    this.updatedAt = updatedAt ? updatedAt : null;
    this.createdAt = createdAt ? createdAt : null;
  }
}
