import moment from "moment";

export default class License {
  constructor({
    id,
    rowNum,
    fileName,
    fileUrl,
    storeid,
    submittedByName,
    createdAt,
    startDate,
    endDate
  }) {
    this.id = id;
    this.rowNum = rowNum || '';
    this.fileName = fileName || 'NA';
    this.fileUrl = fileUrl || 'NA';
    this.storeid = storeid || 'NA';
    this.startDate = startDate ? moment(startDate).format('DD-MM-YYYY') : null;
    this.endDate = endDate ? moment(endDate).format('DD-MM-YYYY') : null;
    this.submittedByName = submittedByName || '';
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY') : null;

  }
}
