import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async list({ storeId } = {}) {
    let url = `${configService.get('apiUrl')}/checklist-v2/fill-user?storeId=${storeId}`;
    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async reviewChecklists() {
    let url = `${configService.get('apiUrl')}/reviewChecklist-v2`;
    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async listReleasedReports({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/releasedReports`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async getOne({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/checklist-v2/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getOneReportData({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/getOne-report-v2/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async createReport({ params } = {}) {
    let url = `${configService.get('apiUrl')}/checklist-V2-report`;
    return axios
      .post(url, params)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async submitCheckpoint({ params } = []) {
    let url = `${configService.get('apiUrl')}/checkpoint-V2-submission`;

    return axios
      .post(url, params)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async userFilledReports() {
    const url = `${configService.get('apiUrl')}/getsubmittedreport/user`;

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getSubmittedCheckpoints({ reportId } = {}) {
    const url = `${configService.get('apiUrl')}/getSubmitted-checkpoints-v2/${reportId}`;

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  // getOne-report-v2/:id
  async releaseReport({ params } = {}) {
    const { reportId } = params;
    return axios
      .patch(`${configService.get('apiUrl')}/checklist-report-v2/${reportId}`, params)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async checkSubmittedChecklistV2({ query } = {}) {
    const pickedQuery = _.pick(query, ['storeId', 'checklist']);
    let url = `${configService.get('apiUrl')}/checkSubmittedChecklistV2`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async downloadKeyPointReports({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/getAllKeyPointReport`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteFoodSafetyReport({ id }) {
    return axios
      .delete(`${configService.get('apiUrl')}/report-v2-delete/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
