import moment from 'moment';

export default class NPS {
  constructor({
    id,
    index,
    storeid,
    billNumber,
    billType,
    billDate,
    billAmount,
    mobile,
    ratingDate,
    questionGroup,
    createdAt,
    submittedByName,
    isSubmitted,
    actionStatus,
    storeActionRemarks,
    updatedAt
  }) {
    this.id = id || '';
    this.index = index + 1;
    this.storeid = storeid || '';
    this.billNumber = billNumber || '';
    this.billType = billType || '';
    this.billDate = billDate ? moment(billDate).format('DD-MM-YYYY') : null;
    this.billAmount = billAmount || '';
    this.mobile = mobile || '';
    this.ratingDate = ratingDate ? moment(ratingDate).format('DD-MM-YYYY hh:mm:  A') : null;
    this.questionGroup = questionGroup || '';
    this.createdAt = createdAt ? moment(createdAt).format('DD-MM-YYYY hh:mm: A') : null;
    this.submittedByName = submittedByName || '';
    this.isSubmitted = isSubmitted === true ? true : false;
    this.actionStatus = actionStatus || '';
    this.storeActionRemarks = storeActionRemarks || '';
    this.updatedAt = updatedAt ? moment(updatedAt).format('DD-MM-YYYY hh:mm:  A') : null;
  }
}
