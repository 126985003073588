import moment from 'moment';

export default class Videos {
  constructor({
    id,
    rowNum,
    videoUrl,
    uploadedByName,
    createdAt,
    regions,
    clusters,
    storeids,
    formats,
    startDate,
    endDate,
    active,
    title,
    description,
    watchBy,
    type
  }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.videoUrl = videoUrl || '';
    this.uploadedByName = uploadedByName || '';
    this.createdAt = createdAt ? moment(createdAt).format('LL') : null;
    this.regions = regions || '';
    this.clusters = clusters || '';
    this.storeids = storeids || '';
    this.formats = formats || '';
    this.startDate = startDate ? moment(startDate).format('LL') : null;
    this.endDate = endDate ? moment(endDate).format('LL') : null;
    this.active = active ? 'True' : 'False';
    this.title = title || '';
    this.description = description || '';
    this.watchBy = watchBy || '';
    this.type = type || '';
  }
}
