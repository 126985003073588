import moment from "moment";

export default class Schedule {

  constructor({
    id,
    rowNum,
    scheduleType,
    scheduleDate,
    stores,
    createdByName,
    createdAt,
    index = 0
  }) {
    this.id = id;
    this.rowNum = rowNum || '';
    this.scheduleType = scheduleType || '';
    this.stores = stores || '';
    this.scheduleDate = scheduleDate ? moment(scheduleDate).format('LL') : null;
    this.createdByName = createdByName || '';
    this.createdAt = createdAt ? moment(createdAt).format('LLL') : null;
    this.index = index + 1;

  }
}
