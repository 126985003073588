import _ from 'lodash';
import checklistV2Service from '@/services/checklistV2Service';
import { ChecklistV2 } from '../../model/checklistV2';
import Vue from 'vue';

const state = {
  baseUrl: '/checklistV2',
  checklistsV2: [],
  pagination: {},
  loading: false,
  checklistV2: null,
  checkpoints: []
};

const actions = {
  list({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setChecklistsV2', { checklistsV2: [], pagination: {} });

    commit('startRequest');
    checklistV2Service
      .list({ query })
      .then(response => {
        commit('setChecklistsV2', { checklistsV2: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setChecklistV2', { checklistV2: {} });
    checklistV2Service
      .getOne({ id })
      .then(response => {
        commit('setChecklistV2', { checklistV2: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { checklistV2, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    checklistV2Service
      .postOne({ checklistV2 })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New checklistV2 has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, checklistV2, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    checklistV2Service
      .patchOne({ id, newChecklistV2: checklistV2 })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'checklistV2 has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  downloadCheckpoints({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    checklistV2Service
      .downloadCheckpoints({ id })
      .then(response => {
        commit('setCheckpoints', { checkpoints: response.data });
      })
      .catch(e => {
        if (e.response.status === 404) {
          Vue.swal({
            title: 'ChecklistV2 Not Active',
            text: 'We could not download the checkpoints because the checklistV2 is not active.',
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6'
          });
        }
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setChecklistsV2(state, { checklistsV2, pagination }) {
    state.checklistsV2 = _.map(checklistsV2, (checklistV2, index) => {
      const newChecklistV2 = new ChecklistV2({
        index,
        id: checklistV2._id, // eslint-disable-line
        name: checklistV2.name,
        active: checklistV2.active,
        startDate: checklistV2.startDate,
        endDate: checklistV2.endDate,
        storeids: checklistV2.storeids.join(','),
        categories: checklistV2.categories.join(','),
        departments: checklistV2.departments.join(','),
        clusters: checklistV2.clusters.join(','),
        regions: checklistV2.regions.join(','),
        formats: checklistV2.formats.join(','),
        shareTo: checklistV2.shareTo.map(shareTo => shareTo.email).join(','),
        permissions: checklistV2.permissions.map(permissions => permissions.name).join(','),
        roles: checklistV2.roles.map(roles => roles.name).join(','),
        frequency: checklistV2.frequency,
        activeTillTime: checklistV2.activeTillTime
      });
      return newChecklistV2;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setChecklistV2(state, { checklistV2 }) {
    state.checklistV2 = new ChecklistV2({
      id: checklistV2._id, // eslint-disable-line
      name: checklistV2.name,
      active: checklistV2.active,
      startDate: checklistV2.startDate,
      endDate: checklistV2.endDate,
      storeids: checklistV2.storeids,
      categories: checklistV2.categories,
      departments: checklistV2.departments,
      clusters: checklistV2.clusters ? checklistV2.clusters : [],
      regions: checklistV2.regions ? checklistV2.regions : [],
      formats: checklistV2.formats ? checklistV2.formats : [],
      notifyTo: checklistV2.notifyTo ? checklistV2.notifyTo : [],
      shareTo: checklistV2.shareTo ? checklistV2.shareTo : [],
      permissions: checklistV2.permissions,
      roles: checklistV2.roles,
      frequency: checklistV2.frequency,
      checkpoints: checklistV2.checkpoints
        ? checklistV2.checkpoints.map(c => ({
            ...c,
            isImageMandatory: c.isImageMandatory ? 'True' : 'False'
          }))
        : [],
      checkpointType: checklistV2.checkpointType,
      activeTillTime: checklistV2.activeTillTime,
      isImageMandatory: checklistV2.isImageMandatory
    });

    state.loading = false;
  },
  setCheckpoints(state, { checkpoints }) {
    state.checkpoints = checkpoints;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
