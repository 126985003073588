import feedbackService from '@/services/feedbackService';
import Feedback from '../../model/feedback';

const state = {
  baseUrl: '/feedback',
  pagination: {},
  feedbackList: [],
  loading: false,
  feedback: null
};

const actions = {
  listFeedback({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setFeedbacks', { feedbacks: [], pagination: {} });

    commit('startRequest');

    feedbackService
      .listFeedback({ query })
      .then(response => {
        commit('setFeedbacks', { feedbacks: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setFeedback', { feedback: {} });
    feedbackService
      .getOne({ id })
      .then(response => {
        commit('setFeedback', { feedback: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { feedback, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    feedbackService
      .postOne({ feedback })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Feedback has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    feedbackService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listFeedback', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Feedback has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, feedback, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    feedbackService
      .patchOne({ id, newFeedback: feedback })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Feedback has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  upload({ dispatch, commit }, { data, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    feedbackService
      .upload({ data })
      .then(_response => {
        commit('finishRequest');
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            type: 'success',
            text: 'Feedback(s) uploaded successfully !!'
          },
          { root: true }
        );
        router.go();
      })
      .catch(e => {
        let errorMessage = '';
        if (e.response.data.data[0]) {
          errorMessage = e.response.data.data[0].msg;
        }
        commit('requestFailed');
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: errorMessage,
            type: 'error',
            showType: 'dialog',
            text: 'Upload failed, remember to use only sample file provided and check the line number !!'
          },
          { root: true }
        );
        dispatch('common/handleServiceException', { e }, { root: true });
      });
    commit('finishRequest');
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setFeedbacks(state, { feedbacks, pagination }) {
    let row = 1;
    state.feedbackList = feedbacks.map(feedback => {
      const { _id } = feedback;
      const newFeedback = new Feedback({
        rowNum: row,
        id: _id,
        dateOfCalling: feedback.dateOfCalling,
        outcome: feedback.outcome,
        mobile: feedback.mobile,
        remarks: feedback.remarks,
        storeid: feedback.storeid,
        customerName: feedback.customerName,
        submittedByName: feedback.submittedByName
      });
      row += 1;
      return newFeedback;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setFeedback(state, { feedback }) {
    state.feedback = new Feedback({
      id: feedback._id, // eslint-disable-line
      customerName: feedback.customerName,
      dateOfCalling: feedback.dateOfCalling,
      storeid: feedback.storeid,
      mobile: feedback.mobile,
      outcome: feedback.outcome,
      remarks: feedback.remarks
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
