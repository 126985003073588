import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

let storeid;


export default {
  async listPhoto({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'page', 'page_size', 'filter', 'search', 'searchkey', 'startdate', 'enddate']);
    let url = `${configService.get('apiUrl')}/photo-sharing-event-crm`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }


    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },

  async getOne({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/photo-sharing-event/${id}`, {})
      .then(response => {
        storeid = response.data.data.storeid;
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async deleteOne({ id }) {
    return axios
      .delete(`${configService.get('apiUrl')}/photo-sharing-event/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async downloadAll({ images }) {
    axios
      .post(`${configService.get('apiUrl')}/photo-sharing-event/download-photos`, { images }, { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${storeid}.zip`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(e => {
        throw e;
      });
  },
  async downloadEventReport({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q', 'page', 'page_size',]);
    let url = `${configService.get('apiUrl')}/photo-event-report`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      }
      ).catch(e => {
        throw e;
      }
      );
  }
};
