import _ from 'lodash';
import popService from '../../services/popService';
import POPPrint from '../../model/popPrint';
import CustomPopPrint from '../../model/customPopPrint';

const state = {
  baseUrl: '/pop',
  popListsCompare: [],
  popLists: [],
  pagination: {},
  paginationCompare: {},
  popReport: [],
  downloadPopList: [],
  downloadPromoList: [],
  loading: false,
  customLocalPopList: [],
  downloadLoading: false
};

const actions = {
  listPop({ dispatch, commit }, { query = {}, router, compare = false }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setPopList', { popLists: [], pagination: {} });
    commit('setPopListCompare', { popListsCompare: [], paginationCompare: {} });
    commit('startRequest');
    popService
      .list({ query })
      .then(response => {
        if (compare === true) {
          commit('setPopListCompare', {
            popListsCompare: response.data.rows,
            paginationCompare: response.data.pagination
          });
        } else {
          commit('setPopList', {
            popLists: response.data.rows,
            pagination: response.data.pagination
          });
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          { e, router },
          {
            root: true
          }
        );
      });
  },
  report({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setReport', { popReport: [] });
    commit('startRequest');
    popService
      .report({ query })
      .then(response => {
        commit('setReport', { popReport: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          { e, router },
          {
            root: true
          }
        );
      });
  },
  patchOne({ dispatch, commit }, { list, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    popService
      .patchOne({ list })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Items has been marked printed.'
          },
          { root: true }
        );
        if (redirectUrl !== '') {
          location.reload();
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          { e, router },
          {
            root: true
          }
        );
      });
  },
  searchByArticle({ dispatch, commit }, { articleCode, router, compare = false }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setPopList', { popLists: [], pagination: {} });
    commit('setPopListCompare', { popListsCompare: [], paginationCompare: {} });
    commit('startRequest');
    popService
      .searchByArticle({ articleCode })
      .then(response => {
        if (compare === true) {
          commit('setPopListCompare', {
            popListsCompare: response?.data,
            paginationCompare: response.data.pagination
          });
        } else {
          commit('setPopList', {
            popLists: response?.data,
            pagination: response.data.pagination
          });
        }
      })
      .catch(e => {
        let errorMessage = '';
        if (e.response?.data?.data[0]) {
          errorMessage = e.response?.data?.data[0].msg;
        }
        dispatch(
          'alert/error',
          {
            position: 'center',
            title: errorMessage,
            type: 'error',
            showType: 'dialog',
            text: ''
          },
          { root: true }
        );
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          { e, router },
          {
            root: true
          }
        );
      });
  },
  download({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setDownloadPopList', { downloadPopList: [] });
    commit('setDownloadLoading');
    popService
      .list({ query })
      .then(response => {
        commit('setDownloadPopList', {
          downloadPopList: response.data.rows,
          pagination: response.data.pagination
        });
      })
      .catch(e => {
        commit('finishDownloading');
        dispatch(
          'common/handleServiceException',
          { e, router },
          {
            root: true
          }
        );
      });
  },
  downloadPromo({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setDownloadPromoList', { downloadPromoList: [] });
    commit('setDownloadLoading');
    popService
      .promoList({ query })
      .then(response => {
        commit('setDownloadPromoList', {
          downloadPromoList: response.data
        });
      })
      .catch(e => {
        commit('finishDownloading');
        dispatch(
          'common/handleServiceException',
          { e, router },
          {
            root: true
          }
        );
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadPopList', { downloadPopList: [] });
  },
  setDownloadPromoListToEmpty({ commit }) {
    commit('setDownloadPromoList', { downloadPromoList: [] });
    state.loading = false;
  },
  setCustomLocalPopList({ commit }, { printList }) {
    localStorage.removeItem('uploadingArticleList');
    localStorage.setItem('uploadingArticleList', JSON.stringify(printList));
    commit('setCustomLocalPopList', { printList });
  },
  restoreCustomLocalPopList({ commit }) {
    const localStoreData = localStorage.getItem('uploadingArticleList');
    const printList = JSON.parse(localStoreData);
    if (printList && printList.length) {
      commit('setCustomLocalPopList', { printList });
    }
  },
  removeCustomLocalPopList({ commit }) {
    localStorage.removeItem('uploadingArticleList');
    commit('removeCustomLocalPopList');
  },
  updateCustomLocalPopList({ commit }, { articleList }) {
    commit('updateCustomLocalPopList', { articleList });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  setDownloadLoading(state) {
    state.downloadLoading = true;
  },
  finishDownloading(state) {
    state.downloadLoading = false;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setReport(state, { popReport }) {
    state.popReport = popReport;
    // state.loading = false;
  },
  setPopList(state, { popLists, pagination }) {
    state.popLists = _.map(popLists, (popList, index) => {
      const newPopList = new POPPrint({
        index,
        id: popList._id,
        family: popList.family,
        articleCode: popList.articleCode,
        articleDescription: popList.articleDescription,
        createdAt: popList.createdAt,
        delta: popList.delta,
        mrp: popList.mrp,
        offerPrice: popList.offerPrice,
        percentageOff: popList.percentageOff,
        pop1: popList.pop1,
        pop2: popList.pop2,
        pop3: popList.pop3,
        rupeesOff: popList.rupeesOff,
        storeid: popList.storeid,
        storeName: popList.storeName,
        updatedAt: popList.updatedAt,
        validFrom: popList.validFrom,
        validTo: popList.validTo,
        popPaperType: popList.popPaperType,
        isPrinted: popList.isPrinted,
        stockInHand: popList.stockInHand,
        brick: popList.brick,
        offerCommunication: popList.offerCommunication
      });
      return newPopList;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setPopListCompare(state, { popListsCompare, paginationCompare }) {
    state.popListsCompare = _.map(popListsCompare, (popList, index) => {
      const newPopList = new POPPrint({
        index,
        id: popList._id,
        family: popList.family,
        articleCode: popList.articleCode,
        articleDescription: popList.articleDescription,
        createdAt: popList.createdAt,
        delta: popList.delta,
        mrp: popList.mrp,
        offerPrice: popList.offerPrice,
        percentageOff: popList.percentageOff,
        pop1: popList.pop1,
        pop2: popList.pop2,
        pop3: popList.pop3,
        rupeesOff: popList.rupeesOff,
        storeid: popList.storeid,
        storeName: popList.storeName,
        updatedAt: popList.updatedAt,
        validFrom: popList.validFrom,
        validTo: popList.validTo,
        popPaperType: popList.popPaperType,
        isPrinted: popList.isPrinted,
        stockInHand: popList.stockInHand,
        brick: popList.brick
      });
      return newPopList;
    });
    state.paginationCompare = paginationCompare;
    state.loading = false;
  },
  setDownloadPromoList(state, { downloadPromoList }) {
    state.downloadPromoList = downloadPromoList;
    state.downloadLoading = false;
  },

  setDownloadPopList(state, { downloadPopList }) {
    state.downloadPopList = downloadPopList.map((popprint, index) => {
      const newPOPPrint = new POPPrint({
        index,
        id: popprint._id,
        rowNum: popprint.index,
        storeid: popprint.storeid,
        articleCode: popprint.articleCode,
        family: popprint.family,
        articleDescription: popprint.articleDescription,
        delta: popprint.delta,
        mrp: popprint.mrp,
        offerPrice: popprint.offerPrice,
        percentageOff: popprint.percentageOff,
        rupeesOff: popprint.rupeesOff,
        popPaperType: popprint.popPaperType,
        validFrom: popprint.validFrom,
        validTo: popprint.validTo,
        createdAt: popprint.createdAt,
        pop1: popprint.pop1,
        pop2: popprint.pop2,
        pop3: popprint.pop3,
        storeName: popprint.storeName,
        updatedAt: popprint.updatedAt,
        isPrinted: popprint.isPrinted
      });
      return newPOPPrint;
    });
    state.downloadLoading = false;
  },
  setCustomLocalPopList(state, { printList }) {
    state.customLocalPopList = printList.map(print => {
      const newCustomPopPrint = new CustomPopPrint({
        index: print.index,
        articleCode: print.articleCode,
        articleDescription: print.articleDescription,
        family: print.family,
        mrp: print.mrp,
        pop1: print.pop1,
        validTo: print.validTo,
        offerPrice: print.offerPrice,
        percentageOff: print.percentageOff,
        rupeesOff: print.rupeesOff,
        popPaperType: print.popPaperType,
        brick: print.brick,
        isPrinted: print.isPrinted
      });
      return newCustomPopPrint;
    });
  },
  removeCustomLocalPopList(state) {
    state.customLocalPopList = [];
  },
  updateCustomLocalPopList(state, { articleList }) {
    state.customLocalPopList = state.customLocalPopList
      .map(item => {
        if (articleList.includes(item.articleCode)) {
          return {
            ...item,
            isPrinted: true
          };
        }
        return item;
      })
      .sort((x, y) => Number(x.isPrinted) - Number(y.isPrinted));
    localStorage.setItem('uploadingArticleList', JSON.stringify(state.customLocalPopList));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
