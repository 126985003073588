<template>
  <b-navbar
    toggleable="md"
    :class="{ 'navbar-dark bg-dark': !isDarkMode, 'navbar-dark bg-dark border-bottom': isDarkMode }"
    fixed="top"
  >
    <b-navbar-brand :to="{ path: '/' }">
      <img class="box-shadow2" src="../assets/images/Spencers_Logo.png" width="60" />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item :to="{ path: '/dashboard' }" v-if="dashboardNavBarItemPermission" data-cy="nav-bar-dashboard">
          <font-awesome-icon :icon="['fas', 'border-all']" class="mr-1" />Dashboard
        </b-nav-item>
        <b-nav-item
          :to="{ path: '/report' }"
          v-if="reportNavBarItemPermission || specialReportNavBarItemPermission"
          data-cy="nav-bar-dashboard"
        >
          <font-awesome-icon :icon="['fas', 'clipboard-list']" class="mr-1" />Reports
        </b-nav-item>
        <!-- <b-nav-item-dropdown :variant="isDarkMode ? 'primary' : 'success'" -->
        <!--       :menu-class="isDarkMode ? 'bg-dark box-shadow2' : 'box-shadow2'" v-if="specialReportNavBarItemPermission" right data-cy="nav-bar-manage-setting"> -->
        <!--   <template v-slot:button-content> -->
        <!--     <font-awesome-icon :icon="['fas', 'chart-line']" class="mr-1" />Reports -->
        <!-- </template> -->
        <!-- <b-dropdown-item                   :link-class="isDarkMode ? 'text-light bg-dark' : ''" :to="{ path: '/report' }" v-if="reportPermission" data-cy="nav-bar-staff"> -->
        <!--   <font-awesome-icon :icon="['fas', 'chart-bar']" class="mr-1" />Report -->
        <!-- </b-dropdown-item> -->
        <!-- <b-dropdown-item -->
        <!--  :link-class="isDarkMode ? 'text-light bg-dark' : ''" -->
        <!--   v-if="foodSafetyPermission" -->
        <!--   :to="{ path: '/foodSafety_Reports' }" -->
        <!--   data-cy="nav-bar-general-settings" -->
        <!-- > -->
        <!--   <font-awesome-icon :icon="['fas', 'seedling']" class="mr-1" /> Food Safety Reports -->
        <!-- </b-dropdown-item> -->
        <!-- </b-nav-item-dropdown> -->

        <b-nav-item-dropdown
          :menu-class="isDarkMode ? 'bg-dark box-shadow2' : 'box-shadow2'"
          v-if="checklistNavBarItemPermission"
          right
          data-cy="nav-bar-manage-setting"
        >
          <template v-slot:button-content>
            <font-awesome-icon :icon="['fas', 'check']" class="mr-1" />Checklist
          </template>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            :to="{ path: '/checklist' }"
            data-cy="nav-bar-staff"
            v-if="viewChecklistPermission"
          >
            <font-awesome-icon :icon="['fas', 'eye']" class="mr-1" /> View Checklist
          </b-dropdown-item>
          <!-- <b-dropdown-item -->
          <!-- :link-class="isDarkMode ? 'text-light bg-dark' : ''" -->
          <!--   :to="{ path: '/checklistV2' }" -->
          <!--   data-cy="nav-bar-staff" -->
          <!--   v-if="viewFoodSafetyChecklistPermission" -->
          <!-- > -->
          <!--   <font-awesome-icon :icon="['fas', 'leaf']" class="mr-1" />Food Safety Checklist -->
          <!-- </b-dropdown-item> -->
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            :to="{ path: '/checklist-fill' }"
            v-if="fillChecklistPermission"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" /> Fill Checklist
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item :to="{ path: '/form' }" v-if="dynamicFormNavBarPermission" data-cy="nav-bar-staff">
          <font-awesome-icon :icon="['fas', 'boxes']" class="mr-1" />Dynamic Forms
        </b-nav-item>

        <b-nav-item v-if="eventNavBarItemPermission" :to="{ path: '/photo-sharing-event' }" data-cy="nav-bar-staff">
          <font-awesome-icon :icon="['fas', 'gifts']" class="mr-1" />Events
        </b-nav-item>
        <b-nav-item-dropdown
          :menu-class="isDarkMode ? 'bg-dark box-shadow2' : 'box-shadow2'"
          v-if="npsNavBarItemPermission"
          right
          data-cy="nav-bar-manage-setting"
        >
          <template v-slot:button-content> <font-awesome-icon :icon="['fas', 'receipt']" class="mr-1" />NPS </template>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            :to="{ path: '/vendorFeedbackReport' }"
            data-cy="nav-bar-general-settings"
            v-if="npsVenderPermission"
          >
            <font-awesome-icon :icon="['fas', 'shopping-bag']" class="mr-1" /> NPS Vendor
          </b-dropdown-item>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            :to="{ path: '/nps' }"
            data-cy="nav-bar-general-settings"
            v-if="npsCustomerPermission"
          >
            <font-awesome-icon :icon="['fas', 'user']" class="mr-1" /> Voice Of Customer
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown
          :menu-class="isDarkMode ? 'bg-dark box-shadow2' : 'box-shadow2'"
          v-if="moreFunctionNavBarItemPermission"
          right
          data-cy="nav-bar-manage-setting"
        >
          <template v-slot:button-content>
            <font-awesome-icon :icon="['fas', 'sitemap']" class="mr-1" />More Functions
          </template>

          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            :to="{ path: '/complain' }"
            v-if="supportTicketsPermission"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'ticket-alt']" class="mr-1" /> Support Tickets
          </b-dropdown-item>

          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            :to="{ path: '/pop' }"
            v-if="popPermission"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'print']" class="mr-1" />POP
          </b-dropdown-item>

          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            v-if="ticketsPermission"
            :to="{ path: '/complain-tickets' }"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'ticket-alt']" class="mr-1" /> Tickets
          </b-dropdown-item>

          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            v-if="transactionComplainPermission"
            :to="{ path: '/transaction-complain' }"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'ticket-alt']" class="mr-1" /> Transaction Complain
          </b-dropdown-item>

          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            v-if="osaReportPermission"
            :to="{ path: '/barcodeScan' }"
            data-cy="nav-bar-general-settings"
            ><font-awesome-icon :icon="['fas', 'barcode']" class="mr-1" /> OSA Report
          </b-dropdown-item>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            v-if="kycPermission"
            :to="{ path: '/kyc' }"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'handshake']" class="mr-1" /> KYC
          </b-dropdown-item>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            v-if="kycInstitutionalPermission"
            :to="{ path: '/kycInstitutional' }"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'fingerprint']" class="mr-1" /> KYC Institutional
          </b-dropdown-item>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            v-if="storeLicensedPermission"
            :to="{ path: '/license-upload' }"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'id-card']" class="mr-1" />Store Licenses
          </b-dropdown-item>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            v-if="promoCodePermission"
            :to="{ path: '/promo-code' }"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'ad']" class="mr-1" /> Promo Code
          </b-dropdown-item>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            :to="{ path: '/gift-distribute-app' }"
            data-cy="nav-bar-general-settings"
            v-if="giftIssuancePermission"
          >
            <font-awesome-icon :icon="['fas', 'hand-holding-usd']" class="mr-1" /> Gift Issuance
          </b-dropdown-item>

          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            v-if="commentsPermission"
            :to="{ path: '/comments' }"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'comments']" class="mr-1" /> Comments
          </b-dropdown-item>

          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            v-if="notesPermission"
            :to="{ path: '/notes' }"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'pen']" class="mr-1" /> Notes
          </b-dropdown-item>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            :to="{ path: '/schedule' }"
            v-if="schedulesPermission"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'calendar-check']" class="mr-1" /> Schedules
          </b-dropdown-item>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            :to="{ path: '/tags' }"
            v-if="tagsPermission"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'tags']" class="mr-1" /> Tags
          </b-dropdown-item>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            v-if="storeDocumentPermission"
            :to="{ path: '/document-share' }"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'clipboard-list']" class="mr-1" />Store Documents
          </b-dropdown-item>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            :to="{ path: '/video' }"
            v-if="videosPermission"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'play']" class="mr-1" /> Videos
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          :menu-class="isDarkMode ? 'bg-dark box-shadow2' : 'box-shadow2'"
          v-if="staffNavBarItemPermission"
          right
          data-cy="nav-bar-manage-setting"
        >
          <template v-slot:button-content> <font-awesome-icon :icon="['fas', 'users']" class="mr-1" />Staff </template>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            :to="{ path: '/user' }"
            data-cy="nav-bar-general-settings"
            v-if="userPermission"
          >
            <font-awesome-icon :icon="['fas', 'user-tie']" class="mr-1" /> Users
          </b-dropdown-item>

          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            :to="{ path: '/role' }"
            data-cy="nav-bar-general-settings"
            v-if="rolesPermission"
          >
            <font-awesome-icon :icon="['fas', 'at']" class="mr-1" /> Roles
          </b-dropdown-item>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            v-if="storesPermission"
            :to="{ path: '/stores' }"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'store']" class="mr-1" /> Stores
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item :to="{ path: '/petty-cash' }" v-if="pettyCashNavBarItemPermission" data-cy="nav-bar-staff">
          <font-awesome-icon :icon="['fas', 'rupee-sign']" class="mr-1" />Petty Cash<span class="custom_plus"></span>
        </b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item id="tooltip-button-disable" v-if="isDarkMode" @click="handleDarkMode()">
          <font-awesome-icon :icon="['fas', 'sun']" class="mr-1" />
        </b-nav-item>
        <b-nav-item id="tooltip-button-disable" v-if="!isDarkMode" @click="handleDarkMode()">
          <font-awesome-icon :icon="['fas', 'moon']" class="mr-1" />
        </b-nav-item>
        <b-tooltip :hidden="handleTooltip" variant="info" target="tooltip-button-disable">
          Use Disha With Eyes Comfort
        </b-tooltip>
        <b-nav-item class="contactButton" :to="{ path: '/contactUs' }">
          <font-awesome-icon :icon="['fas', 'envelope']" class="mr-1" /> Help
        </b-nav-item>
        <b-nav-item-dropdown
          :menu-class="isDarkMode ? 'bg-dark box-shadow2' : 'box-shadow2'"
          right
          data-cy="nav-bar-manage-setting"
        >
          <template v-slot:button-content> <font-awesome-icon :icon="['fas', 'user']" class="mr-1" />Profile </template>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            data-cy="nav-bar-welcome-text"
            v-if="user"
          >
            <font-awesome-icon class="mr-1" /> Welcome, {{ user.name.split(' ')[0] }}
          </b-dropdown-item>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            target="_blank"
            link-classes="btn btn-grey mx-1"
            href="https://yvm.spencersretail.com "
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'check']" class="mr-1" />Go To YVM
          </b-dropdown-item>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            target="_blank"
            link-classes="btn btn-grey mx-1"
            href="https://spencer-doc-share.s3.ap-south-1.amazonaws.com/CRM+V1+ENG+EDITED.pdf"
            data-cy="nav-bar-general-settings"
          >
            <font-awesome-icon :icon="['fas', 'question-circle']" class="mr-1" />User Manual
          </b-dropdown-item>
          <b-dropdown-item
            :link-class="isDarkMode ? 'text-light bg-dark' : ''"
            :to="{ path: '/logout' }"
            link-classes="btn btn-grey mx-1"
            data-cy="nav-bar-logout"
          >
            <font-awesome-icon :icon="['fas', 'sign-out-alt']" class="mr-1" /> Logout
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <!-- <b-nav-item v-if="user" data-cy="nav-bar-welcome-text" @click="onClick">Help</b-nav-item> -->
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { Permissions } from '../helper/navBarHelper';
export default {
  name: 'NavBar',
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('cstSubmission', ['store']),
    ...mapGetters('auth', ['isFoodSafetyTeam', 'isFoodSafetyAdmin'])
  },
  mounted() {
    this.getStoreByUser();
    this.handlePermissions();
  },
  data() {
    return {
      handleTooltip: false,
      darkMode: 'light',
      specialReportNavBarItemPermission: false,
      dashboardNavBarItemPermission: false,
      reportNavBarItemPermission: false,
      checklistNavBarItemPermission: false,
      dynamicFormNavBarPermission: false,
      eventNavBarItemPermission: false,
      npsNavBarItemPermission: false,
      moreFunctionNavBarItemPermission: false,
      staffNavBarItemPermission: false,
      pettyCashNavBarItemPermission: false,
      reportPermission: false,
      foodSafetyPermission: false,
      viewChecklistPermission: false,
      viewFoodSafetyChecklistPermission: false,
      fillChecklistPermission: false,
      npsVenderPermission: false,
      npsCustomerPermission: false,
      supportTicketsPermission: false,
      popPermission: false,
      ticketsPermission: false,
      transactionComplainPermission: false,
      osaReportPermission: false,
      kycPermission: false,
      kycInstitutionalPermission: false,
      storeLicensedPermission: false,
      promoCodePermission: false,
      giftIssuancePermission: false,
      commentsPermission: false,
      notesPermission: false,
      schedulesPermission: false,
      tagsPermission: false,
      storeDocumentPermission: false,
      videosPermission: false,
      userPermission: false,
      rolesPermission: false,
      storesPermission: false,
      assignedStore: ''
    };
  },
  watch: {
    store(newValue) {
      if (newValue.length > 0) {
        this.assignedStore = newValue.map(item => item.storeid);
      }
    }
  },
  methods: {
    ...mapActions('cstSubmission', ['getStoreByUser']),
    handleDarkMode() {
      this.handleTooltip = true;
      this.$emit('handleDark', {});
    },
    handlePermissions() {
      const userPermissions = Permissions(this.user, this.store);
      if (userPermissions) {
        userPermissions.forEach(permission => {
          if (this.$data.hasOwnProperty(permission)) {
            this.$data[permission] = true;
          }
        });
      } else {
        const defaultUser = [
          'reportNavBarItemPermission',
          'checklistNavBarItemPermission',
          'fillChecklistPermission',
          'dynamicFormNavBarPermission',
          'eventNavBarItemPermission',
          'moreFunctionNavBarItemPermission',
          'ticketsPermission',
          'osaReportPermission',
          'kycPermission',
          'commentsPermission',
          'notesPermission'
        ];
        defaultUser.forEach(permission => {
          if (this.$data.hasOwnProperty(permission)) {
            this.$data[permission] = true;
          }
        });
      }
    }
  }
};
</script>
