import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listVendor({ query = {} } = {}) {
    const pickedQuery = _.pick(query, [
      'q',
      'page',
      'page_size',
      'filter',
      'search',
      'searchkey',
      'startdate',
      'enddate'
    ]);
    let url = `${configService.get('apiUrl')}/vendorFeedback`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getOne({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/vendorFeedback/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async patchOne({ id, rating }) {
    return axios
      .patch(`${configService.get('apiUrl')}/vendorFeedback/${id}`, rating)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  // async postOne({ rating } = {}) {
  //   return axios
  //     .post(`${configService.get('apiUrl')}/vendorFeedback/`, rating)
  //     .then(response => {
  //       return response.data;
  //     })
  //     .catch(e => {
  //       throw e;
  //     });
  // }
  async postOne({ rating, query = {} } = {}) {
    const pickedQuery = _.pick(query, ['vendorCode', 'vendorName']);
    let url = `${configService.get('apiUrl')}/vendorFeedback`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }
    return axios
      .post(url, rating, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
