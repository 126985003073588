import _ from 'lodash';
import giftService from '../../services/giftService';
import Gift from '../../model/gift';

const state = {
  baseUrl: '/gift-distribute-app',
  giftList: [],
  downloadGiftList: [],
  pagination: {},
  loading: false
};

const actions = {
  listGift({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setGiftList', { giftList: [], pagination: null });

    commit('startRequest');

    giftService
      .listGift({ query })
      .then(response => {
        commit('setGiftList', { giftList: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch(
          'common/handleServiceException',
          {
            e,
            router
          },
          { root: true }
        );
      });
  },
  download({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setDownloadGiftList', {
      downloadGiftList: []
    });

    giftService
      .listGift({ query })
      .then(response => {
        commit('setDownloadGiftList', {
          downloadGiftList: response.data.rows,
          pagination: response.data.pagination
        });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  setDownloadListToEmpty({ commit }) {
    commit('setDownloadGiftList', {
      downloadGiftList: []
    });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setGiftList(state, { giftList, pagination }) {
    let row = 1;
    state.giftList = giftList.map(gif => {
      const { _id } = gif;
      const newGift = new Gift({
        rowNum: row,
        id: _id,
        giftName: gif.giftName,
        giftPromoId: gif.giftPromoId !== [] ? gif.giftPromoId.join(',') : '',
        storeid: gif.storeid,
        billNumber: gif.billNumber,
        tillNumber: gif.tillNumber,
        billAmount: gif.billAmount,
        customerMobile: gif.customerMobile,
        billDate: gif.billDate,
        submittedByName: gif.submittedByName,
        createdAt: gif.createdAt
      });
      row += 1;
      return newGift;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setDownloadGiftList(state, { downloadGiftList }) {
    let row = 1;
    state.downloadGiftList = _.map(downloadGiftList, gif => {
      const { _id } = gif;
      const formattedGifts = new Gift({
        id: _id,
        rowNum: row,
        giftName: gif.giftName,
        giftPromoId: gif.giftPromoId !== [] ? gif.giftPromoId.join(',') : '',
        storeid: gif.storeid,
        billNumber: gif.billNumber,
        tillNumber: gif.tillNumber,
        billAmount: gif.billAmount,
        customerMobile: gif.customerMobile,
        billDate: gif.billDate,
        submittedByName: gif.submittedByName,
        createdAt: gif.createdAt
      });
      row += 1;
      return formattedGifts;
    });
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
