import moment from 'moment';

export default class Events {
  constructor({
    id,
    index,
    name,
    active,
    startDate,
    endDate,
    storeids,
    clusters,
    regions,
    roles,
    eventTask,
    formats,
    submittedBy,
    submittedByName,
    frequency,
    createdAt
  }) {
    this.id = id || '';
    this.index = index + 1;
    this.name = name || '';
    this.active = active ? 'True' : 'False';
    this.storeids = storeids || '';
    this.startDate = startDate ? moment(startDate).format('LL') : null;
    this.endDate = endDate ? moment(endDate).format('LL') : null;
    this.clusters = clusters || '';
    this.regions = regions || '';
    this.formats = formats || '';
    this.roles = roles || '';
    this.eventTask = eventTask || '';
    this.submittedByName = submittedByName || '';
    this.createdAt = createdAt ? moment(createdAt).format('LL') : null;
    this.submittedBy = submittedBy || '';
    this.frequency = frequency || 'DAILY';
  }
}
