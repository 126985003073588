import _ from 'lodash';
import eventsService from '@/services/eventsService';
import Events from '../../model/events';

const state = {
  baseUrl: '/eventplanlist',
  pagination: {},
  events: [],
  setDownloadMisCountList: [],
  misCountList: [],
  loading: false,
  event: null
};

const actions = {
  downloadMis({ dispatch, commit }, { query = {} }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');
    commit('setDownloadMisCountList', { misCountList: [] });
    eventsService
      .downloadMis({ query })
      .then(response => {
        commit('setDownloadMisCountList', { misCountList: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e }, { root: true });
      });
  },
  listEvents({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setEvents', { events: [], pagination: {} });

    commit('startRequest');

    eventsService
      .listEvents({ query })
      .then(response => {
        commit('setEvents', { events: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  postOne({ dispatch, commit }, { event, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    eventsService
      .postOne({ event })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'New Event has been added.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  getOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    commit('setEvent', { event: {} });

    eventsService
      .getOne({ id })
      .then(response => {
        commit('setEvent', { event: response.data });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  patchOne({ dispatch, commit }, { id, event, router, redirectUrl = '' }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    eventsService
      .patchOne({ id, newEvent: event })
      .then(_response => {
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: 'Event has been updated.'
          },
          { root: true }
        );

        if (redirectUrl !== '') {
          router.push(redirectUrl);
        }
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  },
  deleteOne({ dispatch, commit }, { id, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('startRequest');

    eventsService
      .deleteOne({ id })
      .then(_response => {
        dispatch('listEvents', { id });
        dispatch(
          'alert/success',
          {
            showType: 'toast',
            position: 'center',
            title: '',
            text: ' Event has been deleted.'
          },
          { root: true }
        );
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setEvents(state, { events, pagination }) {
    state.events = _.map(events, (event, index) => {
      const newEvents = new Events({
        index,
        id: event._id, // eslint-disable-line
        name: event.name,
        active: event.active,
        startDate: event.startDate,
        endDate: event.endDate,
        storeids: event.storeids ? event.storeids.join(',') : '',
        clusters: event.clusters ? event.clusters.join(',') : '',
        regions: event.regions ? event.regions.join(',') : '',
        formats: event.formats ? event.formats.join(',') : '',
        roles: event.roles.map(roles => roles.name).join(','),
        submittedByName: events.submittedByName,
        createdAt: events.createdAt,
        submittedBy: events.submittedBy,
        eventName: events.eventName,
        frequency: event.frequency
      });
      return newEvents;
    });
    state.pagination = pagination;
    state.loading = false;
  },
  setEvent(state, { event }) {
    state.event = new Events({
      id: event._id, // eslint-disable-line
      name: event.name,
      active: event.active,
      startDate: event.startDate,
      endDate: event.endDate,
      storeids: event.storeids,
      clusters: event.clusters ? event.clusters : [],
      regions: event.regions ? event.regions : [],
      formats: event.formats ? event.formats : [],
      roles: event.roles,
      eventTask: event.eventTask ? event.eventTask.map(e => ({ ...e, name: e.name })) : [],
      frequency: event.frequency
    });
    state.loading = false;
  },
  setDownloadMisCountList(state, { misCountList }) {
    state.misCountList = misCountList;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
