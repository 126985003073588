import _ from 'lodash';
import axios from 'axios';
import configService from '@/services/configService';
import utils from '@/helper/utils';

export default {
  async listRole({ query = {} } = {}) {
    const pickedQuery = _.pick(query, ['q']);
    let url = `${configService.get('apiUrl')}/roles`;
    if (pickedQuery) {
      url += `?${utils.toQueryStrings(pickedQuery)}`;
    }

    return axios
      .get(url, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async getOne({ id }) {
    return axios
      .get(`${configService.get('apiUrl')}/roles/${id}`, {})
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async postOne({ role } = {}) {
    return axios
      .post(`${configService.get('apiUrl')}/roles/`, role)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  },
  async patchOne({ id, role }) {
    return axios
      .patch(`${configService.get('apiUrl')}/roles/${id}`, role)
      .then(response => {
        return response.data;
      })
      .catch(e => {
        throw e;
      });
  }
};
