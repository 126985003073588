import _ from 'lodash';
import cstSubmissionIdService from '../../services/cstSubmissionIdService';
import CSTSubmission from '../../model/cstSubmissionId';

const state = {
  baseUrl: '/cstTask',
  pagination: {},
  submissions: [],
  loading: false,
  submission: {},
  submissionUrl: null
};

const actions = {
  listSubmissionsById({ dispatch, commit }, { query = {}, router }) {
    dispatch('alert/clear', {}, { root: true });
    commit('setSubmissions', { submissions: [], pagination: {} });

    commit('startRequest');

    cstSubmissionIdService
      .listSubmissionsById({ query })
      .then(response => {
        commit('setSubmissions', { submissions: response.data.rows, pagination: response.data.pagination });
      })
      .catch(e => {
        commit('requestFailed');
        dispatch('common/handleServiceException', { e, router }, { root: true });
      });
  }
};

const getters = {};

const mutations = {
  startRequest(state) {
    state.loading = true;
  },
  requestFailed(state) {
    state.loading = false;
  },
  setSubmissions(state, { submissions, pagination }) {
    let row = 1;
    state.submissions = _.map(submissions, submission => {
      const newSubmissions = new CSTSubmission({
        id: submission._id, // eslint-disable-line
        rowNum: row,
        optionSelected: submission.optionSelected,
        submittedByName: submission.submittedByName,
        cstTask: submission.cstTask.taskName ? submission.cstTask.taskName : null,
        createdAt: submission.createdAt,
        attachments: submission.attachments
      });
      row += 1;
      return newSubmissions;
    });
    state.pagination = pagination;
    state.loading = false;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
