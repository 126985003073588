import moment from 'moment';

export default class POPPrint {
  constructor({
    id,
    index,
    storeid,
    articleCode,
    delta,
    family,
    articleDescription,
    pop1,
    mrp,
    offerPrice,
    percentageOff,
    rupeesOff,
    popPaperType,
    validFrom,
    validTo,
    pop2,
    pop3,
    storeName,
    createdAt,
    updatedAt,
    isPrinted,
    stockInHand,
    brick,
    offerCommunication
  }) {
    this.id = id || '';
    this.index = index + 1;
    this.storeid = storeid || '';
    this.articleCode = articleCode;
    this.delta = delta || '';
    this.family = family || '';
    this.articleDescription = articleDescription || '';
    this.pop1 = pop1 || '';
    this.mrp = mrp || 0;
    this.offerPrice = offerPrice || 0;
    this.percentageOff = percentageOff || 0;
    this.rupeesOff = rupeesOff || 0;
    this.popPaperType = popPaperType || '';
    this.validFrom = validFrom ? moment(validFrom).format('YYYY-MM-DD') : null;
    this.validTo = validTo ? moment(validTo).format('YYYY-MM-DD') : null;
    this.pop2 = pop2 || '';
    this.pop3 = pop3 || '';
    this.storeName = storeName || '';
    this.createdAt = createdAt ? moment(createdAt).format('YYYY-MM-DD') : null;
    this.updatedAt = updatedAt ? moment(updatedAt).format('DD-MM-YYYY hh:mm a') : null;
    this.isPrinted = isPrinted === true;
    this.brick = brick || '';
    this.stockInHand = stockInHand || 0;
    this.offerCommunication = offerCommunication || '';
  }
}
