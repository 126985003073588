import moment from 'moment';

export default class Feedback {
  constructor({ id, rowNum, dateOfCalling, outcome, mobile, remarks, storeid, customerName, submittedByName }) {
    this.id = id || '';
    this.rowNum = rowNum || '';
    this.customerName = customerName || '';
    this.mobile = mobile || '';
    this.dateOfCalling = dateOfCalling ? moment(dateOfCalling).format('LL') : null;
    this.storeid = storeid || '';
    this.submittedByName = submittedByName || '';
    this.outcome = outcome || '';
    this.remarks = remarks || '';



  }
}
