const state = {
  isDarkMode: localStorage.getItem('darkMode') === 'true'
};

const actions = {
  changeDarkMode({ commit }, { isDarkMode }) {
    commit('setDarkMode', { isDarkMode });
    localStorage.setItem('darkMode', isDarkMode);
  }
};

const getters = {};

const mutations = {
  setDarkMode(state, { isDarkMode }) {
    state.isDarkMode = isDarkMode;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
